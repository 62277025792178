import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Tag, Button, Input, message, Select, Drawer, Col, Row, Form, Tabs, ConfigProvider, Modal, Spin, Flex, Upload, Checkbox } from "antd";
import IResponse from '../types/response';
import { EditOutlined, DeleteOutlined, CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { useFetchCountry } from '../services/countries.service';
import { useFetchStates } from '../services/states.service';
import { useFetchCities } from '../services/cities.service';
import { compareObjects, formatDateTime, getLabel, isNotNullUndefined, isNotNullUndefinedBlank, isValidEmail, isValidPhoneNumber } from "../helpers/common";
import Images from "../assets/image";
import { useNavigate } from 'react-router-dom';
import Pagination from "../helpers/pagination";
import { useAddAgentDocument, useApproveRejectAgent, useCompleteAgentProfile, useFetchAgentList, useFetchAgentStatuses, useGetDocumentTypes, useUpdateAgentStatus, useFetchAgentProfileInfo } from "../services/agent.service";
import { DeleteObjectCommand, DeleteObjectCommandInput, S3, ObjectCannedACL } from '@aws-sdk/client-s3';
import { awsS3config } from '../helpers/config';
import { IEditUser } from "../types/user";
import { storage } from "../utils/storage";
import { useTimer } from "../helpers/useTimer";
import { useSendVerificationCode, useUpdateVerifiedStatus, useVerifyUserVerificationCode } from "../services/user.service";
import { useFetchLanguages } from "../services/language.service";
import { IDocumentTypes } from "../types/documenttypes";
import { Upload as S3Upload } from '@aws-sdk/lib-storage';
import { eventEmitter } from "../helpers/eventemitter";


const Attendants: React.FC = () => {

    const { Search } = Input;
    const { Column } = Table;
    const [form] = Form.useForm();
    const [otpForm] = Form.useForm();
    const [loading, setIsLoading] = useState(false);
    const [agentListData, setAgentList] = useState<any>([]);
    const [profileImage, setProfileImage] = useState(Images.userprofile);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isEditing, setIsEditing] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [editAgent, setEditingAgent] = useState<any>(null);
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [countries, setCountry] = useState<any[]>([]);
    const [stateOptions, setStateOptions] = useState<any[]>([]);
    const [cityOptions, setCityOptions] = useState<any[]>([]);
    // const [states, setState] = useState<any[]>([]);
    // const [cityList, setCityList] = useState<any[]>([]);
    const [serviceCityList, setServiceCityList] = useState<any[]>([]);
    const [open, setOpen] = useState(false);
    const statusId = useRef(0);
    const { TabPane } = Tabs;
    const pageNumber = useRef(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(10);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState<any>(null);
    const [reason, setReason] = useState('');
    const [agentId, setDeletedAgentId] = useState('');
    const navigate = useNavigate();
    const [agentAllStatuses, setAgentAllStatuses] = useState<any[]>([]);
    const [agentStatuses, setAgentStatuses] = useState<any[]>([]);
    const agentStatusOptions = useRef<any>(null);
    const agentDefaultStatus = useRef<any>({});
    const [formUpdateValues, setFormUpdateValues] = useState<any>({});
    const [modalType, setModalType] = useState<'approve' | 'reject' | 'delete' | 'update' | ''>('');

    const [isEmailVerified, setEmailVerified] = useState(false);
    const [isSmsVerified, setSmsVerified] = useState(false);
    const isEmailType = useRef(false);
    const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(false);
    const codeType = useRef('');
    const [modalLoading, setModalLoading] = useState(false);
    const { resendTimer, resetTimer } = useTimer();
    const [languageList, setLanguageList] = useState<any[]>([]);
    const [documentTypes, setDocumentTypes] = useState<IDocumentTypes[]>([]);
    const [documentUrl, setDocumentUrl] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);
    const formInitialValues = useRef<any>({});
    const formUpdatedValues = useRef<any>({});
    const searchValue = useRef<string>("");
    const [agentGalleryImages, setAgentGalleryImages] = useState<any[]>([]);
    const phoneNo = useRef<string>('');
    const email = useRef<string>('');
    const [isProvideTransportation, setIsProvideTransportation] = useState(false);
    const [isNoTransportation, setIsNoTransportation] = useState(false);
    const [agentPayoutPreferences] = useState<any[]>([]);

    //#region on-load useEffect operations
    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId !== 1) {
            navigate("/");
            return;
        }
        else {
            setIsLoading(true);
            fetchCountry();
            fetchAgentList(pageNumber.current, pageSize, searchValue.current ?? "");
            fetchAgentStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, statusId]);
    //#endregion on-load useEffect operations

    //#region fetch countries, states, cities.

    const fetchCountry = async () => {
        if (countries.length <= 0) {
            fetchCountryMutation.mutate({
                searchFilter: "",
                isServiceable: true
            });
        }
    };

    // const fetchCountryMutation = useFetchCountry({
    //     onSuccess: (response: IResponse) => {
    //         if (response != null && response !== undefined) {
    //             response.data = response;
    //             const filteredCountries = response.data.filter((country: { countryId: number }) => country.countryId === 1);
    //             setCountry(filteredCountries);
    //             const defaultCountry = response.data.find((x: { countryId: number; }) => x.countryId === 1);
    //             if (defaultCountry) {
    //                 const defaultOption = {
    //                     value: defaultCountry.countryId,
    //                     label: (
    //                         <div style={{ display: 'flex', alignItems: 'center' }}>
    //                             <img src={defaultCountry.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
    //                             {defaultCountry.dialingCode} {defaultCountry.countryName}
    //                         </div>
    //                     )
    //                 };
    //                 const option = { value: defaultOption.value, label: defaultOption.label.props.children };
    //                 handleCountryCodeChange(option);
    //             }
    //         } else {
    //             message.error("Unexpected response format:", response);
    //         }
    //     },
    //     onError: (error: any) => {
    //         alert(error.message);
    //     },
    // });

    const fetchCountryMutation = useFetchCountry({
        onSuccess: (response: any) => {
            if (response != null && response !== undefined) {
                setCountry(response);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    const onCountryChange = async (countryId: string = "") => {
        fetchState(countryId);
        fetchServiceableCity(countryId);
    };

    const fetchState = async (countryId: string = "") => {
        setCityOptions([]);
        setStateOptions([]);
        form.setFieldValue("state", "");
        form.setFieldValue("city", "");
        if (isNotNullUndefinedBlank(countryId)) {
            fetchStateMutation.mutate({
                countryId: countryId,
                searchFilter: ""
            });
        }
    };

    const fetchStateMutation = useFetchStates({
        onSuccess: (response: any, request) => {
            if (isNotNullUndefined(response)) {
                if (isNotNullUndefinedBlank(request.countryId)) {
                    setStateOptions(response.data);
                }
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchCity = async (countryId: string = "", stateId: string = "") => {
        setCityOptions([]);
        form.setFieldValue("city", "");
        if (isNotNullUndefinedBlank(stateId) && isNotNullUndefinedBlank(countryId)) {
            fetchCityMutation.mutate({
                stateId: stateId,
                countryId: countryId,
                searchFilter: "",
                isFavourite: "",
                isServiceable: ""
            });
        }
    };

    const fetchServiceableCity = async (countryId: string = "") => {
        setServiceCityList([]);
        form.setFieldValue("preferredCities", null);
        if (isNotNullUndefinedBlank(countryId)) {
            fetchServiceableCityMutation.mutate({
                stateId: "",
                countryId: countryId,
                searchFilter: "",
                isFavourite: "",
                isServiceable: true
            });
        }
    };

    const fetchCityMutation = useFetchCities({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response)) {
                setCityOptions(response.data);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchServiceableCityMutation = useFetchCities({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response)) {
                setServiceCityList(response.data);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    //#endregion fetch countries, states, cities.

    //#region agent operations

    const onSearch = (value: any) => {
        pageNumber.current = 1;
        setPageSize(10);
        searchValue.current = value;
        fetchAgentList(pageNumber.current, pageSize, value);
    };

    const tabClicked = (key: number) => {
        setAgentList([]);
        const status = agentStatuses.find((x: any) => x.statusId === key);
        if (status) {
            statusId.current = status.statusId;
            pageNumber.current = 1;
            fetchAgentList(pageNumber.current, pageSize, searchValue.current ?? "");
        }
    };

    const showDrawer = (record: any) => {
        setIsLoading(false);
        agentDefaultStatus.current = { statusId: record.statusId, statusCode: '', statusName: record.statusName, statusType: '' };
        fetchAgentProfileInfo(record.agentId);
    };

    const onDeleteAgent = (record: any) => {
        setModalType('delete');
        setFormUpdateValues({ fullName: record.fullName, status: 99 });
        setIsModalVisible(true);
        setDeletedAgentId(record.agentId);
    }

    //#endregion agent operations

    //#region fetch agent operations

    const fetchAgentList = async (pageNumber: number, pageSize: number, search: string) => {
        setAgentList([]);
        setIsLoading(true);
        fetchAgentListMutation.mutate({
            statusId: statusId.current,
            pageNumber: pageNumber,
            pageSize: pageSize,
            searchFilter: search
        });
    };

    const fetchAgentListMutation = useFetchAgentList({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                if (isNotNullUndefined(response.data) && response.data.length > 0) {
                    const formattedData = response.data.map((item: any) => ({
                        ...item,
                        lastLoginAt: item.lastLoginAt ? formatDateTime(item.lastLoginAt, "DD/MM/YYYY HH:mm AMPM") : "",
                        activateDeactivate: (item.statusName !== "Disabled") ? ["on"] : ["off"],
                    }));
                    setAgentList(formattedData);
                    setTotalPages(Math.ceil(formattedData[0].recordCount / pageSize));
                    setIsLoading(false);
                } else {
                    setAgentList([]);
                    setIsLoading(false);
                }
            } else {
                message.error(response.statusMessage);
                setIsLoading(false);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
            setIsLoading(false);
        }
    });

    //#endregion fetch agent operations

    //#region fetch agent-data on show edit-drawer.

    const fetchAgentProfileInfo = async (agentId: string) => {
        fetchAgentProfileInfoMutation.mutate(agentId);
    };

    const fetchAgentProfileInfoMutation = useFetchAgentProfileInfo({
        onSuccess: async (response: any) => {
            if (response?.data) {
                const agentProfile = response.data.agentProfile;
                const agentGalleryImages = response.data.agentGalleryImage;
                agentStatusOptions.current = (agentAllStatuses.length > 0) ? agentAllStatuses.filter((x: any) => [1, 98, 99, agentProfile.statusId].includes(x.statusId)) : [];
                setEditingAgent(agentProfile);
                setAgentGalleryImages(agentGalleryImages);
                fetchServiceableCity(parseInt(agentProfile.countryId) > 0 ? agentProfile.countryId : "");
                fetchStateCities(agentProfile.countryId, agentProfile.stateId);
                formInitialValues.current = {
                    agentId: agentProfile.agentId,
                    fullName: agentProfile.fullName,
                    status: agentProfile.statusId,
                    phoneNoDialingCode: isNotNullUndefinedBlank(agentProfile.phoneNoDialingCode) ? agentProfile.phoneNoDialingCode?.trim() : (countries.find(x => x.countryId === agentProfile.countryId)?.dialingCode?.trim() ?? ""),
                    phoneNoCountryId: agentProfile.phoneNoCountryId,
                    phoneNo: agentProfile.phoneNo,
                    email: agentProfile.email,
                    gender: agentProfile.gender,
                    agentBio: agentProfile.agentBio,
                    hourlyRate: agentProfile.hourlyRate,
                    preferredLanguages: (agentProfile.preferredLanguages.map(Number).every((x: any) => x === 0) ? null : agentProfile.preferredLanguages.map(Number)),
                    transportation: agentProfile.transportation,
                    country: agentProfile.countryId,
                    state: agentProfile.stateId,
                    city: agentProfile.cityId,
                    address: agentProfile.address,
                    preferredCities: (agentProfile.preferredCities.map(Number).every((x: any) => x === 0) ? null : agentProfile.preferredCities.map(Number)),
                    documentTypeId: agentProfile.documentTypeId,
                    documentUrl: agentProfile.documentUrl,
                    isEmailVerified: agentProfile.isEmailVerified,
                    isPhoneNoVerified: agentProfile.isPhoneNoVerified,
                    payoutPreferenceId: agentProfile.payoutPreferenceId,
                    payoutPreferenceName: agentProfile.payoutPreferenceName,
                    accountInformation: agentProfile.accountInformation
                };
                setIsProvideTransportation(agentProfile.transportation);
                setIsNoTransportation(!agentProfile.transportation);
                setProfileImage(agentProfile.imageUrl || Images.userprofile);
                form.setFieldsValue({
                    agentId: agentProfile.agentId,
                    fullName: agentProfile.fullName,
                    email: agentProfile.email,
                    phoneNoDialingCode: agentProfile.phoneNoDialingCode,
                    phoneNoCountryId: agentProfile.phoneNoCountryId,
                    phoneNo: agentProfile.phoneNo,
                    gender: agentProfile.gender,
                    imageUrl: agentProfile.imageUrl,
                    address: agentProfile.address,
                    country: parseInt(agentProfile.countryId ?? 0) > 0 ? parseInt(agentProfile.countryId) : null,
                    state: parseInt(agentProfile.stateId ?? 0) > 0 ? parseInt(agentProfile.stateId) : null,
                    city: parseInt(agentProfile.cityId ?? 0) > 0 ? parseInt(agentProfile.cityId) : null,
                    status: agentProfile.statusId ?? "",
                    agentBio: agentProfile.agentBio,
                    hourlyRate: agentProfile.hourlyRate ?? "",
                    preferredCities: (agentProfile.preferredCities.map(Number).every((x: any) => x === 0) ? null : agentProfile.preferredCities.map(Number)),
                    preferredLanguages: (agentProfile.preferredLanguages.map(Number).every((x: any) => x === 0) ? null : agentProfile.preferredLanguages.map(Number)),
                    transportation: agentProfile.transportation,
                    rejectReason: agentProfile.rejectReason,
                    documentTypeId: parseInt(agentProfile.documentTypeId) > 0 ? parseInt(agentProfile.documentTypeId) : null,
                    documentUrl: agentProfile.documentUrl,
                    isApproved: agentProfile.isApproved,
                    isAgentDocumentExists: agentProfile.isAgentDocumentExists,
                    agentPreference: agentProfile.agentPreference,
                    isEmailVerified: agentProfile.isEmailVerified,
                    isPhoneNoVerified: agentProfile.isPhoneNoVerified,
                    payoutPreferenceId: agentProfile.payoutPreferenceId,
                    payoutPreferenceName: agentProfile.payoutPreferenceName,
                    accountInformation: agentProfile.accountInformation
                });

                const defaultCountry = countries.find(x => x.dialingCode?.trim() === agentProfile.phoneNoDialingCode?.trim());
                if (defaultCountry) {
                    const defaultOption = {
                        value: defaultCountry.countryId,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={defaultCountry.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
                                {defaultCountry.dialingCode} {defaultCountry.countryName}
                            </div>
                        )
                    };
                    const option = { value: defaultOption.value, label: defaultOption.label.props.children };
                    handleCountryCodeChange(option);
                }
                fetchAllLanguages();
                fetchDocumentTypes();
                setEmailVerified(agentProfile.isEmailVerified);
                setSmsVerified(agentProfile.isPhoneNoVerified);
                setDocumentUrl(agentProfile.documentUrl);
                eventEmitter.emit("clickableChange", true);
                setOpen(true);
                setFileType(getFileType(agentProfile.documentUrl));
                phoneNo.current = agentProfile.phoneNo;
                email.current = agentProfile.email;
                renderPreview();
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    const fetchStateCities = (countryId: any, stateId: any) => {
        fetchStateOptions(parseInt(countryId) > 0 ? countryId.toString() : "");
        fetchCity(countryId.toString(), (parseInt(stateId) > 0 ? stateId.toString() : ""));
    };

    const fetchAgentStatus = async () => {
        fetchAgentStatusMutation.mutate({});
    }

    const fetchAgentStatusMutation = useFetchAgentStatuses({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response) && response.statusCode === 200) {
                response.data.unshift({ statusId: 0, statusCode: '', statusName: 'All', statusType: '' });
                setAgentAllStatuses(response.data);
                setAgentStatuses(response.data.filter((x: any) => [0, 1, 6, 10, 97, 98, 99, 100].includes(x.statusId)));
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchAllLanguages = async () => {
        fetchAllLanguageMutation.mutate({});
    };

    const fetchAllLanguageMutation = useFetchLanguages({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response.data)) {
                setLanguageList(response.data);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchDocumentTypes = async () => {
        fetchDocumentTypesMutation.mutate({});
    };

    const fetchDocumentTypesMutation = useGetDocumentTypes({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                setDocumentTypes(response.data);
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const handleProvideTransportationChange = (e: any) => {
        setIsProvideTransportation(e.target.checked);
        if (e.target.checked) {
            setIsNoTransportation(false);
        }
    };

    const handleNoTransportationChange = (e: any) => {
        setIsNoTransportation(e.target.checked);
        if (e.target.checked) {
            setIsProvideTransportation(false);
        }
    };

    const getFileType = (url: any) => {
        const extension = isNotNullUndefined(url) ? url.split('.').pop().toLowerCase() : "";
        if (extension === 'pdf') {
            return 'application/pdf';
        } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff'].includes(extension)) {
            return 'image';
        } else {
            return '';
        }
    }

    const renderPreview = () => {
        if (fileType?.includes('image')) {
            return (
                <div className="mt-6 h-32 flex">
                    <a href={documentUrl || undefined} target="_blank" rel="noopener noreferrer">
                        <img src={documentUrl || undefined} alt="Uploaded" className="h-full object-contain" />
                    </a>
                    {/* <Button onClick={handleRemove} icon={<DeleteOutlined />} className="ml-5 !px-[15px]">
                    </Button> */}
                </div>
            );
        } else if (fileType === 'application/pdf') {
            return (
                <div className="mt-6 items-center flex">
                    <a href={documentUrl ?? undefined} target='_blank' rel="noreferrer">
                        <span>Uploaded PDF</span>
                    </a>
                    {/* <Button onClick={handleRemove} icon={<DeleteOutlined />} className="ml-5">
                    </Button> */}
                </div>
            );
        }
        return null;
    };

    // const handleRemove = () => {
    //     setDocumentUrl(null);
    //     setFileType(null);
    //     message.success('Document has been removed successfully.');
    // };

    //#endregion fetch agent-data on show edit-drawer.

    //#region agent edit-drawer operations

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            if (isNotNullUndefinedBlank(profileImage) && profileImage !== Images.userprofile) {
                onDeleteImageFile();
            }
            const fileName = "agent_profile" + new Date().getTime() + "." + file.name.split(".")[1];
            try {

                const bucket = new S3({
                    credentials: {
                        accessKeyId: awsS3config.accessKeyId,
                        secretAccessKey: awsS3config.secretAccessKey,
                    },
                    region: awsS3config.region,
                });

                const params = {
                    Bucket: awsS3config.bucketName,
                    Key: "temp/" + fileName,
                    Body: file,
                    ContentType: file.type,
                    ACL: ObjectCannedACL.public_read_write
                };

                const upload = new S3Upload({
                    client: bucket,
                    params: params,
                });
                upload.done().then((data) => {
                    const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                    file.url = fileUrl;
                    setProfileImage(fileUrl);
                },
                    (err) => {
                        let errorJson = JSON.stringify(err);
                        let errorObject = JSON.parse(errorJson);
                        let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                            'Something went wrong. Please try again!';
                        message.error(msg);
                        return false;
                    });
            } catch (error) {
                message.error("Error uploading file.");
                return false;
            }

        }
    };

    const onDeleteImageFile = async () => {
        if (isNotNullUndefinedBlank(profileImage)) {
            const fileName = profileImage?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: profileImage?.includes("/temp/") ? "temp/" + fileName : "Agent/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                setProfileImage("");
            }
        }
    };

    const handleEditClick = () => {
        const inputElement = document.getElementById('profileImageInput');
        if (inputElement) {
            inputElement.click();
        }
    };

    const handleUpload = (file: any) => {
        if (file) {
            if (isNotNullUndefinedBlank(documentUrl)) {
                onDeleteFeatureImageFile();
            }
            const fileName = "agent_document" + new Date().getTime() + "." + file.name.split(".")[1];
            try {

                const bucket = new S3({
                    credentials: {
                        accessKeyId: awsS3config.accessKeyId,
                        secretAccessKey: awsS3config.secretAccessKey,
                    },
                    region: awsS3config.region,
                });

                const params = {
                    Bucket: awsS3config.bucketName,
                    Key: "temp/" + fileName,
                    Body: file,
                    ContentType: file.type,
                    ACL: ObjectCannedACL.public_read_write
                };

                const upload = new S3Upload({
                    client: bucket,
                    params: params,
                });
                upload.done().then((data) => {
                    const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                    file.url = fileUrl;
                    setDocumentUrl(fileUrl);
                    setFileType(file.type);
                },
                    (err) => {
                        let errorJson = JSON.stringify(err);
                        let errorObject = JSON.parse(errorJson);
                        let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                            'Something went wrong. Please try again!';
                        message.error(msg);
                        return false;
                    });
            } catch (error) {
                message.error("Error uploading file.");
                return false;
            }
        }
        return false;
    };

    const onDeleteFeatureImageFile = async () => {
        if (isNotNullUndefinedBlank(documentUrl)) {
            const fileName = documentUrl?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: documentUrl?.includes("/temp/") ? "temp/" + fileName : "AgentDocument/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                setDocumentUrl("");
            }
        }
    };

    const handleCountryCodeChange = (option: any) => {
        if (Array.isArray(option.label)) {
            const slicedLabel = option.label.slice(0, 2);
            const modifiedOption = { ...option, label: slicedLabel };
            setSelectedCountry(modifiedOption);
        } else {
            const newLabel = { ...option.label, props: { ...option.label.props, children: [...option.label.props.children.slice(0, 3), '', ...option.label.props.children.slice(4)] } };
            option.label = newLabel;
            setSelectedCountry(option);
        }
    };

    const { Option } = Select;

    const onGenderChange = (value: string) => {
        switch (value) {
            case 'male':
                form.setFieldsValue({ note: 'Hi, man!' });
                break;
            case 'female':
                form.setFieldsValue({ note: 'Hi, lady!' });
                break;
            default:
        }
    };

    const onChangePhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (!/^[0-9]*$/.test(value)) {
            value = value.replace(/[^0-9]/g, '');
        }
        if (value.length > 12) {
            value = value.slice(0, 12);
        }
        form.setFieldValue(e.target.id, value);
        if (e.target.id === "phoneNo") {
            phoneNo.current = value;
        }
    };

    const onChangeEmail = (e: { target: { value: any; }; }) => {
        let value = e.target.value;
        form.setFieldValue("email", value);
        email.current = value;
    };

    const onChangeHourlyRate = (e: any) => {
        let value = e.target.value.trim().replace(/[^0-9.]/g, '');
        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts.slice(0, 2).join('.');
        } else if (parts.length === 2) {
            value = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
        value = value.slice(0, 9);
        form.setFieldsValue({ hourlyRate: value });
    };

    const fetchStateOptions = (countryId: any) => {
        setStateOptions([]);
        fetchState(countryId);
    }

    const onUpdateAgent = async (agentProfile: { agentId: string, fullName: string, status: number, email: string, gender: string, agentBio: string, hourlyRate: string, preferredLanguages: any, preferredCities: any, address: string, documentUrl: string, rejectReason: string, isAgentDocumentExists: number, country: number, state: number, city: number, agentPreference: string, isActive: boolean, statusId: number, isApproved: boolean, isEmailVerified: boolean, isPhoneNoVerified: boolean, phoneNo: number, phoneNoCountryId: number, documentTypeId: number }) => {
        formUpdatedValues.current = {
            agentId: agentProfile.agentId,
            fullName: agentProfile.fullName,
            status: agentProfile.status,
            phoneNo: agentProfile.phoneNo,
            phoneNoDialingCode: selectedCountry ? (countries.find(x => x.countryId === selectedCountry.value)?.dialingCode?.trim() ?? "") : "",
            phoneNoCountryId: agentProfile.phoneNoCountryId,
            email: agentProfile.email,
            gender: agentProfile.gender,
            agentBio: agentProfile.agentBio,
            hourlyRate: agentProfile.hourlyRate,
            preferredLanguages: agentProfile.preferredLanguages,
            transportation: isProvideTransportation,
            country: agentProfile.country,
            state: agentProfile.state,
            city: agentProfile.city,
            address: agentProfile.address,
            preferredCities: agentProfile.preferredCities,
            documentTypeId: agentProfile.documentTypeId,
            documentUrl: agentProfile.documentUrl,
            isEmailVerified: agentProfile.isEmailVerified,
            isPhoneNoVerified: agentProfile.isPhoneNoVerified,
        };
        if (compareObjects(formInitialValues.current, formUpdatedValues.current)) {
            onClose();
        }
        else {
            setModalType('update');
            setFormUpdateValues(agentProfile);
            setIsModalVisible(true);
        }
    };

    const onClose = () => {
        setOpen(false);
        setIsEditing(false);
        setEditingAgent(null);
        form.resetFields();
        eventEmitter.emit("clickableChange", false);
    };

    //#endregion agent edit-drawer operations

    //#region confirmation modal operations

    const getModalTitle = () => {
        let message = "";
        if (modalType === 'approve') {
            message = "Confirm Approval!";
        } else if (modalType === 'reject') {
            message = "Confirm Rejection!";
        } else if (modalType === 'delete') {
            message = "Confirm Termination!";
        } else if (modalType === 'update') {
            message = "Confirm Update!";
        }
        return message;
    }

    const getModelContent = () => {
        let modalContent = <></>;
        if (modalType === 'approve') {
            modalContent = (
                <p>Are you sure you want to <b>approve</b> this Attendant's screening request? Once approved, the attendant will be granted access and their status will be updated accordingly.<b>Do you wish to proceed?</b></p>
            );
        } else if (modalType === 'reject') {
            modalContent = (
                <>
                    <p className="mb-4">
                        Are you sure you want to <b>reject</b> this Attendant's screening request? Please provide a reason for the rejection below. The attendant will be notified with the provided reason. <b>Do you wish to proceed?</b>
                    </p>
                    <b className="block mb-2">Reason for Rejection:</b>
                    <Input.TextArea
                        rows={4}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder="Enter reason for rejection"
                        className="border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    />
                </>
            );
        }
        else if (modalType === 'update') {
            const changedStatus = agentStatuses.find((x: any) => x.statusId === formUpdateValues.status);
            const isReasonRequired = ['Temporarily Blacklisted', 'Terminated'].includes(changedStatus?.statusName);

            modalContent = (
                <>
                    <p className="mb-4">Are you sure you want to {modalType} the details for <b>{formUpdateValues.fullName}</b>? This action cannot be undone.</p>
                    {isReasonRequired && (
                        <>
                            <b className="block mb-2">Reason for {changedStatus.statusName}:</b>
                            <Input.TextArea
                                rows={4}
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                placeholder={`Enter reason for ${changedStatus.statusName}`}
                                className="border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                            />
                        </>
                    )}
                </>
            );
        } else if (modalType === "delete") {
            modalContent = (
                <>
                    <p className="mb-4">Are you sure you want to terminate <b>{formUpdateValues.fullName}</b></p>
                    <b className="block mb-2">Reason for Termination:</b>
                    <Input.TextArea
                        rows={4}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder={`Enter reason for Termination`}
                        className="border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    />
                </>
            );
        }
        else {
            modalContent = (
                <p>Are you sure you want to <b>Update</b> this Attendant's Profile request? Once Updated, the attendant can be edited and their details will be updated accordingly.<b>Do you wish to proceed?</b></p>
            );
        }
        return modalContent;
    }

    const handleSave = async () => {
        try {
            if (modalType === 'approve') {
                await approveRejectAgent(selectedAgent.agentId, true, '');
                message.success("Attendant's document has been successfully approved.");
            } else if (modalType === 'reject') {
                if (!reason) {
                    message.error('Please provide a reason for rejection.');
                    return;
                }
                if (reason.length > 300) {
                    message.error('The reason cannot exceed 300 characters.');
                    return;
                }
                await approveRejectAgent(selectedAgent.agentId, false, reason);
                message.success("Attendant's document has been rejected.");
            } else if (modalType === 'delete') {
                if (!reason) {
                    message.error(`Please provide a reason for terminate user.`);
                    return;
                }
                if (reason.length > 300) {
                    message.error('The reason cannot exceed 300 characters.');
                    return;
                }
                updateAgentStatus.mutate({ agentId: agentId, statusCode: "TERMINATED", reason: reason });
            }
            else if (modalType === 'update') {
                const changedStatus = agentStatuses.find((x: any) => x.statusId === formUpdateValues.status);
                const isReasonRequired = ['Temporarily Blacklisted', 'Terminated'].includes(changedStatus?.statusName);

                if (isReasonRequired && !reason) {
                    message.error(`Please provide a reason for ${changedStatus.statusName}.`);
                    return;
                }
                if (reason.length > 300) {
                    message.error('The reason cannot exceed 300 characters.');
                    return;
                }
                else if (modalType === 'update') {
                    const initialDocumentValues = { agentId: formInitialValues.current.agentId, documentTypeId: formInitialValues.current.documentTypeId, documentUrl: formInitialValues.current.documentUrl };
                    const currentDocumentValues = { agentId: formInitialValues.current.agentId, documentTypeId: form.getFieldValue("documentTypeId"), documentUrl: documentUrl };
                    if (compareObjects(initialDocumentValues, currentDocumentValues)) {
                        setIsLoading(true);
                        agentProfileUpdate();
                    } else {
                        setIsLoading(true);
                        addAgentDocument.mutate({
                            agentId: currentDocumentValues.agentId,
                            documentTypeId: currentDocumentValues.documentTypeId,
                            documentUrl: currentDocumentValues.documentUrl
                        });
                    }
                }
            }
            setReason('');
            setIsModalVisible(false);
        } catch (error) {
            message.error('Failed to save');
        }
    };

    const handleCancel = () => {
        setIsLoading(false);
        setIsVerifyModalVisible(false);
        setIsModalVisible(false);
    };

    const addAgentDocument = useAddAgentDocument({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                message.success(response.statusMessage);
                agentProfileUpdate();
            } else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });


    const approveRejectAgent = async (agentId: string, isApproved: boolean, rejectReason: string) => {
        approveRejectAgentMutation.mutate({ agentId, isApproved, rejectReason });
    };

    const approveRejectAgentMutation = useApproveRejectAgent({
        onSuccess: async (response) => {
            if (isNotNullUndefined(response)) {
                if (response.data?.isApproved) {
                    updateAgentStatus.mutate({ agentId: selectedAgent.agentId, statusCode: 'ACTIVE', reason: "" });
                    setIsModalVisible(false);
                } else {
                    setSelectedAgent((record: any) => ({
                        ...record,
                        documentUrl: response.data?.documentUrl,
                    }));

                    fetchAgentList(pageNumber.current, pageSize, searchValue.current ?? "");
                }
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            message.error(error.message);
        },
    });

    const agentProfileUpdate = () => {
        const transportationValue = isProvideTransportation ? 'true' : 'false';
        updateAgentProfile.mutate({
            agentId: formUpdateValues.agentId,
            fullName: formUpdateValues.fullName ?? "",
            email: formUpdateValues.email ?? "",
            statusId: formUpdateValues.status ?? 0,
            phoneNoDialingCode: selectedCountry ? (countries.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "") : "",
            phoneNoCountryId: formUpdateValues.phoneNoCountryId,
            phoneNo: form.getFieldValue("phoneNo"),
            imageUrl: profileImage, //editAgent?.imageUrl,
            gender: formUpdateValues.gender ?? "",
            bio: formUpdateValues.agentBio ?? "",
            hourlyRate: parseInt(formUpdateValues.hourlyRate ?? 0),
            countryId: formUpdateValues.country ?? 0,
            stateId: formUpdateValues.state ?? 0,
            cityId: formUpdateValues.city ?? 0,
            address: formUpdateValues.address ?? "",
            rejectReason: reason ?? "",
            documentUrl: formUpdateValues.documentUrl ?? "",
            isAgentDocumentExists: formUpdateValues.isAgentDocumentExists,
            agentGalleryImages: agentGalleryImages,
            agentPreference: [{
                preferenceName: "Cities",
                preferenceValue: (isNotNullUndefined(formUpdateValues.preferredCities) && formUpdateValues.preferredCities.length > 0) ? formUpdateValues.preferredCities.join(",") : ""
            },
            {
                preferenceName: "Languages",
                preferenceValue: (isNotNullUndefined(formUpdateValues.preferredLanguages) && formUpdateValues.preferredLanguages.length > 0) ? formUpdateValues.preferredLanguages.join(",") : ""
            },
            {
                preferenceName: "Transportation",
                preferenceValue: transportationValue
            }
            ]
        });
    };

    const updateAgentProfile = useCompleteAgentProfile({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200) {
                setOpen(false);
                message.success(response.statusMessage);
            } else {
                message.warning(response.statusMessage);
                setIsLoading(false);
            }
            onActivateAgentClick("");
            onClose();
            fetchAgentList(pageNumber.current, pageSize, searchValue.current ?? "");
        },
        onError: (error: any) => {
            setIsLoading(false);
            message.error(error.message);
        }
    });

    const onActivateAgentClick = (agent: any) => {
        activateAgent(agent);
    };

    const activateAgent = (agent: any) => {
        if (agent.isAgentDocumentExists) {
            if (agent.isApproved) {
                updateAgentStatus.mutate({ agentId: agent.agentId, statusCode: 'ACTIVE', reason: "" });
            } else if (!agent.isAgentDocumentExists) {
                updateAgentStatus.mutate({ agentId: agent.agentId, statusCode: 'PROFILE_UPDATED', reason: "" });
            } else if (agent.isAgentDocumentExists && !agent.isApprove) {
                updateAgentStatus.mutate({ agentId: agent.agentId, statusCode: 'DOCUMENT_UPLOADED', reason: "" });
            }
            else {
                updateAgentStatus.mutate({ agentId: agent.agentId, statusCode: 'WAITING_APPROVAL', reason: "" });
            }
        }
    };

    //#endregion confirmation modal operations

    //#region waiting-for-approval tab operations

    const onApprove = async (record: any) => {
        setSelectedAgent(record);
        setModalType('approve');
        try {
            const isAgentDocumentExists = record?.isAgentDocumentExists;
            if (isAgentDocumentExists) {
                setIsModalVisible(true);
            } else {
                navigate("/agentdocument?id=" + record.agentId);
            }
        } catch (error) {
            message.error('Unable to approve trip request at this time. Please try again later.');
        }
    };

    const onReject = async (record: any) => {
        setSelectedAgent(record);
        setModalType('reject');
        setIsModalVisible(true);
    };

    //#endregion waiting-for-approval tab operations

    //#region otp verify popup operations

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const openVerifyModal = (verificationType: string) => {
        isEmailType.current = (verificationType === "email");
        otpForm.setFieldValue("otpCheck", "");

        const { current: isEmail } = isEmailType;
        const email = form.getFieldValue("email");
        const phoneNo = form.getFieldValue("phoneNo");
        if ((email === "" && isEmail) || (email !== "" && isEmail && !isValidEmail(email))) {
            message.error("Please enter a valid email address");
            return;
        }
        if ((phoneNo === "" && !isEmail) || (phoneNo !== "" && !isEmail && !isValidPhoneNumber(phoneNo))) {
            message.error("Please enter a valid phone number");
            return;
        }

        let countryId = 0;
        if (parseInt(form.getFieldValue("country") ?? 0) > 0) {
            parseInt(form.getFieldValue("country"))
        } else if (isNotNullUndefined(selectedCountry)) {
            countryId = selectedCountry.value
        }
        form.setFieldValue("countryDialingCode", countries.find(x => x.countryId === countryId)?.dialingCode ?? "");
        sendVerificationCodeMutation.mutate({
            userId: form.getFieldValue("agentId") ?? "",
            userEmail: (verificationType === "email") ? email : "",
            phoneNumber: (verificationType === "sms") ? phoneNo : "",
            phoneNoDialingCode: (verificationType === "sms") ? (countries.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "") : ""
        });
        codeType.current = verificationType;
    };

    const handleOtpChange = (event: any) => {
        const isNumberKey = !isNaN(event.key);
        const children = event.currentTarget.children;
        const targetIndex = Array.prototype.indexOf.call(children, event.target);
        let newValue = '';
        Array.prototype.forEach.call(children, (child, index) => {
            if (index === targetIndex) {
                newValue += event.key;
            } else {
                newValue += child.defaultValue;
            }
        });
        const isValidInput = !isNaN(Number(newValue));
        if (!isNumberKey || !isValidInput) {
            if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
                if (/[a-zA-Z\W]/.test(event.key)) {
                    message.error('OTP must only contain numbers.');
                    event.preventDefault();
                }
            }
            return false;
        }
    };

    const onResend = () => {
        sendVerificationCodeMutation.mutate({
            userId: form.getFieldValue("agentId") ?? "",
            userEmail: codeType.current === "email" ? form.getFieldValue("email") : "",
            phoneNumber: codeType.current === "sms" ? form.getFieldValue("phoneNo") : "",
            phoneNoDialingCode: codeType.current === "sms" ? (countries.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "") : ""
        });
        resetTimer();
    };

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                message.success("OTP sent successfully.");
                setIsVerifyModalVisible(true);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const onVerify = () => {
        const code = otpForm.getFieldValue("otpCheck");
        if (isNotNullUndefinedBlank(code) && code.length === 6) {
            setModalLoading(true);
            verifiyVerificationCodeMutation.mutate({
                userEmail: form.getFieldValue("email"),
                phoneNumber: form.getFieldValue("phoneNo"),
                verificationCode: code,
                codeType: codeType.current
            });
        } else {
            message.warning("OTP must be 6 digits.");
        }
    };

    const verifiyVerificationCodeMutation = useVerifyUserVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                updateAgentStatus.mutate({ agentId: form.getFieldValue("agentId") ?? "", statusCode: "OTP_VERIFIED", reason: "" });
                message.success(response.statusMessage);
                let email = (form.getFieldValue("email") ?? "");
                let phoneNo = (form.getFieldValue("phoneNo") ?? "");
                if (!isEmailVerified) {
                    setEmailVerified((email !== "" && codeType.current === "email" && response.statusCode === 200));
                }
                if (!isSmsVerified) {
                    setSmsVerified((phoneNo !== "" && codeType.current === "sms" && response.statusCode === 200));
                }
                setModalLoading(false);
                setIsVerifyModalVisible(false);
            }
            else {
                setModalLoading(false);
                message.error(response.statusMessage);
                otpForm.setFieldValue("otpCheck", "");
            }
        },
        onError: (error) => {
            setModalLoading(false);
            message.error(error.message);
        }
    });

    const updateVerifiedStatusMutation = useUpdateVerifiedStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                fetchAgentList(pageNumber.current, pageSize, "");
            } else {
                message.error(response.statusMessage);
            }
        }, onError: (error) => {
            message.error(error.statusMessage);
        }
    });

    //#endregion otp verify popup operations

    //#region pagination operation

    const handlePageChange = (newPage: number) => {
        pageNumber.current = newPage;
        let size = pageSize;
        setPageSize(size);
        fetchAgentList(newPage, pageSize, searchValue.current ?? "");
    };

    //#endregion pagination operation

    //#region update agent status
    const updateAgentStatus = useUpdateAgentStatus({
        onSuccess: (response: any, request) => {
            if (response.statusCode === 200) {
                if (request.statusCode === "OTP_VERIFIED") {
                    updateVerifiedStatusMutation.mutate({ userId: form.getFieldValue("agentId") ?? "", codeType: codeType.current });
                } else if (request.statusCode === "TERMINATED") {
                    fetchAgentList(pageNumber.current, pageSize, searchValue.current ?? "");
                    message.success("Attendant terminated successfully.")
                } else if (request.statusCode !== "REJECTED") {
                    agentProfileUpdate();
                }
            } else {
                setIsLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    //#endregion update agent status

    return (
        <div>
            <div className="flex justify-between align-middle pb-5">
                <h2 className="text-2xl font-semibold text-black">Attendants</h2>
                <div className="flex justify-between gap-5">
                    <div className="w-80 max-w-80">
                        <Search
                            placeholder="Search user"
                            size="large"
                            enterButton
                            className="search-field"
                            onSearch={onSearch}
                        />
                    </div>
                </div>
            </div>

            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            titleFontSizeLG: 16,
                        },
                    }
                }}
            >

                <Tabs defaultActiveKey="1" onTabClick={(e) => { tabClicked(parseInt(e)) }}>
                    {
                        agentStatuses.map((x: any) => (
                            <TabPane tab={`${x.statusName}`} key={`${x.statusId}`}>
                                <Table dataSource={agentListData} className="userlist-tabs-table" bordered pagination={{ pageSize: pageSize, position: ['none'] }} locale={{
                                    emptyText: loading ? <Spin /> : (
                                        <div className="ant-empty">
                                            <div className="ant-empty-image">
                                                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                        <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                        <g fillRule="nonzero" stroke="#d9d9d9">
                                                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                            <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="ant-empty-description">No data</div>
                                        </div>
                                    )
                                }}>
                                    <Column title="User Name" dataIndex="fullName" key="fullName" align="center" />
                                    <Column title="Email" dataIndex="email" key="email" align="center" />
                                    <Column title="Mobile Number" dataIndex="phoneNo" key="phoneNo" align="center" />
                                    <Column title="Country" dataIndex="countryName" key="countryName" align="center" />
                                    {/* <Column title="State" dataIndex="stateName" key="stateName" align="center" /> */}
                                    <Column title="City" dataIndex="cityName" key="cityName" align="center" />
                                    <Column title="Gender" dataIndex="gender" key="gender" align="center" />
                                    {x.statusId !== 6 && (
                                        <Column
                                            align="center"
                                            title="Status"
                                            dataIndex="statusName"
                                            key="statusName"
                                            render={(tag: string) => (
                                                <>
                                                    {tag ?
                                                        <Tag key={tag} color={tag === "Waiting For Approval" ? "orange" : tag === "Document Uploaded" ? "blue" : tag === "Profile Updated" ? "yellow" : tag === "OTP Verified" ? "lime" : tag === "Draft" ? "cyan" : tag === "Temporarily Unavailable" ? "magenta" : tag === "Temporarily Blacklisted" ? "volcano" : tag === "Terminated" ? "red" : tag === "Rejected" ? "red" : "green"} className="m-0 pl-1 pr-1">
                                                            {tag.toUpperCase()}
                                                        </Tag> : ""}
                                                </>
                                            )}
                                        />
                                    )}

                                    {x.statusId === 100 && statusId.current === 100 ? (
                                        <Column title="Reject Reason" dataIndex="rejectReason" key="rejectReason" align="center" className="word-wrap-column" />
                                    ) : (
                                        <></>
                                    )}
                                    {x.statusId === 98 && (
                                        <Column title="Temporarily Blacklisted Reason" dataIndex="rejectReason" key="rejectReason" align="center" className="word-wrap-column" />
                                    )}
                                    {x.statusId === 99 && (
                                        <Column title="Terminated Reason" dataIndex="rejectReason" key="rejectReason" align="center" className="word-wrap-column" />
                                    )}
                                    {/* {x.statusId === 96 && (
                                        <Column title="Deleted Reason" dataIndex="rejectReason" key="rejectReason" align="center" className="word-wrap-column" />
                                    )} */}
                                    {![6, 10, 100].includes(x.statusId) ? (
                                        <Column title="Last Login" dataIndex="lastLoginAt" key="lastLoginAt" align="center" />
                                    ) : (
                                        <></>
                                    )}
                                    <Column
                                        align="center"
                                        title="Action"
                                        key="action"
                                        render={(_: any, record: any) => (
                                            <Space size="middle">
                                                <Button onClick={() => showDrawer(record)} icon={<EditOutlined />} className="hover:!border-black hover:!text-black"></Button>
                                                {
                                                    (record.statusId !== 99) ?
                                                        <Button onClick={() => onDeleteAgent(record)} icon={<DeleteOutlined />} className="hover:!border-black hover:!text-black"></Button>
                                                        : <></>
                                                }
                                            </Space>
                                        )}
                                    />

                                    {(x.statusId !== 10) && (
                                        <Column
                                            align="center"
                                            title="Document Uploaded"
                                            key="documentUrl"
                                            render={(_: any, record: IEditUser) => (
                                                <>
                                                    {record.isAgentDocumentExists > 0 ? (
                                                        <a href={record.documentUrl} target="_blank" rel="noopener noreferrer">View Document</a>
                                                    ) : (
                                                        "Not Uploaded"
                                                    )}
                                                </>
                                            )}
                                        />
                                    )}
                                    {
                                        x.statusId === 6 && (
                                            <Column
                                                align="center"
                                                title="Document Action"
                                                key="action"
                                                render={(_, record: any) => (
                                                    <Space size="middle">
                                                        <Button
                                                            onClick={() => onApprove(record)}
                                                            className="border-green-400 bg-green-50 text-green-500 hover:border-black hover:text-black"
                                                        >
                                                            Approve
                                                        </Button>
                                                        <Button
                                                            onClick={() => onReject(record)}
                                                            className="border-red-400 bg-red-50 text-red-500 hover:border-black hover:text-black"
                                                        >
                                                            Reject
                                                        </Button>
                                                    </Space>
                                                )}
                                            />
                                        )
                                    }
                                    {/* {item.statusId === 7 && (
                                    <Column title="Reason for rejection" dataIndex="rejectReason" key="rejectReason" align="center" />
                                )} */}
                                </Table>

                                <div>
                                    <Pagination
                                        currentPage={pageNumber.current}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                                <Drawer width={470} title={'Attendant Profile'} onClose={onClose} open={open} className="user-profile-detail" mask={false}>
                                    <div>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={onUpdateAgent}
                                        >
                                            <Form.Item name="agentId" hidden><Input /></Form.Item>
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <div className="flex justify-start items-center mb-5">
                                                        <div className="flex justify-start">
                                                            <div className="w-[90px] h-[90px]">
                                                                <img
                                                                    src={profileImage}
                                                                    className="rounded-full w-full h-full shadow-lg border-4 border-black object-cover"
                                                                    alt="user"
                                                                />
                                                            </div>
                                                            <div
                                                                className="h-7 w-7 text-lg rounded-full bg-black relative flex items-center border-4 border-black justify-center top-[63px] right-[33px] transform origin-center cursor-pointer"
                                                                onClick={handleEditClick}>
                                                                <div className="w-3 h-3">
                                                                    <img src={Images.imageedit} className="w-full h-full" alt="edit-img" />
                                                                </div>
                                                            </div>
                                                            <input
                                                                type="file"
                                                                id="profileImageInput"
                                                                accept="image/*"
                                                                onChange={handleImageChange}
                                                                style={{ display: 'none' }}
                                                            />
                                                        </div>
                                                        <Form.Item
                                                            name="fullName"
                                                            label="Full Name"
                                                            className="mb-0 w-full"
                                                            rules={[{ required: true, message: "Please enter your full name" }]}>
                                                            <Input
                                                                className="h-12 w-full"
                                                                placeholder="Full name"
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="status"
                                                        label="Status"
                                                        rules={[{ required: true, message: "Please Select Status" }]}
                                                    >
                                                        <Select
                                                            className="h-12"
                                                            placeholder="Please select Status"
                                                            optionFilterProp="label"
                                                            options={agentStatusOptions.current ? agentStatusOptions.current.map((x: any) => ({ label: x.statusName, value: x.statusId })) : []}
                                                            allowClear
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="isPhoneNoVerified" hidden><Input /></Form.Item>
                                                    <Form.Item name="countryDialingCode" hidden><Input /></Form.Item>
                                                    <Form.Item
                                                        name="phoneNo"
                                                        label="Phone Number"
                                                        // rules={[
                                                        //     ({ getFieldValue }) => ({
                                                        //         validator(_, value) {
                                                        //             const countryDialingCode = getFieldValue('countryDialingCode') || selectedCountry?.value;

                                                        //             if (value && !countryDialingCode) {
                                                        //                 return Promise.reject(new Error('Please select your country code.'));
                                                        //             }
                                                        //             return Promise.resolve();
                                                        //         },
                                                        //     }),
                                                        // ]}
                                                        //rules={[{ required: true, message: "Please input your Phone number." }]}

                                                        rules={[
                                                            ({ getFieldValue }) => ({
                                                                required: true,
                                                                validator(_, value) {
                                                                    const countryDialingCode = getFieldValue('countryDialingCode') || selectedCountry?.value;
                                                                    if (!value && !countryDialingCode) {
                                                                        return Promise.reject(new Error('Please select your country code and enter your phone number.'));
                                                                    }
                                                                    if (value && !countryDialingCode) {
                                                                        return Promise.reject(new Error('Please select your country code.'));
                                                                    }
                                                                    if (!value && countryDialingCode) {
                                                                        return Promise.reject(new Error('Please enter your phone number.'));
                                                                    }
                                                                    if (value && (value.length < 7 || value.length > 12)) {
                                                                        return Promise.reject(new Error("Phone number must be between 7 and 12 digits."));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                    >
                                                        <Input
                                                            addonBefore={
                                                                <Select
                                                                    labelInValue
                                                                    value={{ value: selectedCountry?.value, label: selectedCountry?.label }}
                                                                    className="h-12 w-[90px] country-code"
                                                                    optionFilterProp="label"
                                                                    onChange={handleCountryCodeChange}
                                                                    options={countries ? countries.map(x => ({
                                                                        label: (
                                                                            <div className="flex items-center">
                                                                                <img src={x.imageUrl} className='w-5 mr-2' alt="Country Flag" />
                                                                                {x.dialingCode} {x.countryName}
                                                                            </div>
                                                                        ),
                                                                        value: x.countryId
                                                                    })) : []}
                                                                    popupClassName="dropdown-max-content"
                                                                // disabled={true}
                                                                />
                                                            }
                                                            onChange={onChangePhoneInput} className="font-normal text-neutral-600 h-12 phone-input" placeholder="Enter your phone number"
                                                            maxLength={12} />

                                                    </Form.Item>
                                                    <div>
                                                        {
                                                            (isSmsVerified) ?
                                                                <div>
                                                                    <img src={Images.righticon} alt="Right-icon" className='absolute z-10 top-[45px] right-5 w-[18px]' />
                                                                </div>
                                                                : <></>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="isEmailVerified" hidden><Input /></Form.Item>
                                                    <Form.Item
                                                        name="email"
                                                        label="Email"
                                                        rules={[{ required: true, message: "Please enter your email" }]}
                                                    >
                                                        <Input className="h-12" onChange={onChangeEmail} />
                                                    </Form.Item>
                                                    <div>
                                                        {
                                                            (isEmailVerified) ?
                                                                <div>
                                                                    <img src={Images.righticon} alt="Right-icon" className='absolute top-[45px] right-5 w-[18px] z-10' />
                                                                </div>
                                                                : <></>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item name="gender" label="Gender" rules={[{ required: true, message: "Please select gender." }]}>
                                                        <Select className='font-medium text-neutral-600 h-12'
                                                            placeholder="Select gender"
                                                            onChange={onGenderChange}
                                                            allowClear
                                                        >
                                                            <Option value="male">Male</Option>
                                                            <Option value="female">Female</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="agentBio"
                                                        label="AgentBio"
                                                        rules={[{ required: true, message: "Please enter your agentBio" }]}
                                                    >
                                                        <Input className="h-12" /></Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="hourlyRate"
                                                        label="HourlyRate"
                                                        rules={[{ required: true, message: "Please enter your hourlyRate" }]}
                                                    >
                                                        <Input className="h-12" onChange={onChangeHourlyRate} /></Form.Item >
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="preferredLanguages"
                                                        label="Language"
                                                        rules={[{ required: true, message: "Please select any language." }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            className="font-medium text-neutral-600 h-12"
                                                            placeholder="Please select Language"
                                                            optionFilterProp="label"
                                                            options={languageList ? languageList.map(x => ({ label: x.languageName, value: x.languageId })) : []}
                                                            allowClear
                                                            mode="multiple"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="country"
                                                        label="Country"
                                                        rules={[{ required: true, message: "Please select your country" }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            className="font-medium text-neutral-600 h-12"
                                                            placeholder="Please select a Country"
                                                            optionFilterProp="label"
                                                            options={countries ? countries.map(x => ({ label: x.countryName, value: x.countryId })) : []}
                                                            onChange={onCountryChange}
                                                            allowClear
                                                        // disabled={true}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="state"
                                                        label="State/Province"
                                                        rules={[{ required: true, message: "Please select your state or province" }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            className="font-medium text-neutral-600 h-12"
                                                            placeholder="Please select State/Province"
                                                            optionFilterProp="label"
                                                            options={stateOptions ? stateOptions.map(x => ({ label: x.stateName, value: x.stateId })) : []}
                                                            onChange={() => fetchCity(form.getFieldValue("country"), form.getFieldValue("state"))}
                                                            allowClear
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="city"
                                                        label="City"
                                                        rules={[{ required: true, message: "Please enter your city" }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            className="font-medium text-neutral-600 h-12"
                                                            placeholder="Please select City"
                                                            optionFilterProp="label"
                                                            options={cityOptions ? cityOptions.map(x => ({ label: x.cityName, value: x.cityId })) : []}
                                                            allowClear
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="address"
                                                        label="Address"
                                                        rules={[{ required: true, message: "Please enter your address" }]}>
                                                        <Input.TextArea
                                                            className="!h-[150px]"
                                                            style={{ resize: 'none', paddingTop: '10px' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="preferredCities"
                                                        label="Select Service Cities"
                                                        rules={[{ required: true, message: "Please select any prefered cities" }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            className="font-medium text-neutral-600 min-h-12 multiple-country"
                                                            placeholder="Please select Cities"
                                                            optionFilterProp="label"
                                                            options={serviceCityList ? serviceCityList.map(x => ({ label: x.cityName, value: x.cityId })) : []} //changes here
                                                            allowClear
                                                            mode="multiple"
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item name="transportation" label="Check this box if you will provide transportation services for travellers during trips. This will help travellers understand your availability for transportation and plan their trips accordingly.">
                                                        <div className="check-box">
                                                            <Checkbox
                                                                checked={isProvideTransportation}
                                                                onChange={handleProvideTransportationChange}
                                                            >
                                                                Provide Transportation Services
                                                            </Checkbox>
                                                        </div>
                                                        <div className="check-box">
                                                            <Checkbox
                                                                checked={isNoTransportation}
                                                                onChange={handleNoTransportationChange}
                                                            >
                                                                No Transportation Services
                                                            </Checkbox>
                                                        </div>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        name="galleryImageUrl"
                                                        label={getLabel("Attendant Gallery Images", false)}
                                                    >
                                                        <div>
                                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                                {agentGalleryImages && agentGalleryImages.length > 0 ? (
                                                                    <div className="agent-gallery">
                                                                        {agentGalleryImages.map(image => (
                                                                            <img key={image.galleryImageUrl} src={image.galleryImageUrl} alt="Agent Gallery" className="gallery-image" />
                                                                        ))}
                                                                    </div>
                                                                ) : (
                                                                    <p>No gallery images available.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="documentTypeId"
                                                        label="Select a document to upload"
                                                        rules={[{ required: true, message: "Please select any document types" }]}
                                                    >
                                                        <Select placeholder="Select a document to upload" className="font-medium text-neutral-600 h-12" allowClear>
                                                            {documentTypes ? documentTypes.map(x => (
                                                                <Option key={x.documentTypeId} value={x.documentTypeId}>
                                                                    {x.documentTypeName}
                                                                </Option>
                                                            )) : ""}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="documentUrl"
                                                        label="Upload your documents"
                                                        rules={[{ required: !isNotNullUndefinedBlank(documentUrl), message: "Please select any document" }]}
                                                    >
                                                        <div className="mt-4">
                                                            <Upload beforeUpload={handleUpload} showUploadList={false} accept="image/*,.pdf">
                                                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                            </Upload>
                                                        </div>
                                                        {documentUrl && renderPreview()}
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="payoutPreferenceName"
                                                        label="Payout Details"
                                                        validateTrigger="onBlur"
                                                    >
                                                        <Input
                                                            className="font-medium text-neutral-600 min-h-12 multiple-country"
                                                            placeholder="Payout Details"
                                                            value={agentPayoutPreferences && agentPayoutPreferences.length > 0 ? agentPayoutPreferences[0].payoutPreferenceName : ''}
                                                            disabled={true}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Account Information"
                                                        name="accountInformation"
                                                        validateTrigger="onBlur"
                                                    >
                                                        <Input.TextArea
                                                            className="!h-[150px]"
                                                            placeholder="Please provide account details."
                                                            style={{ resize: 'none', paddingTop: '10px' }}
                                                            maxLength={500}
                                                            disabled={true}
                                                        />
                                                    </Form.Item>

                                                    <Form.Item name="transportation" label="Follow this link to see how you can setup any of the above payments if you don’t already have one.">
                                                        <div className="text-center">
                                                            <a
                                                                href="https://example.com/payment-setup"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-red-500 font-semibold underline"
                                                            >
                                                                Payment Setup
                                                            </a>
                                                        </div>
                                                    </Form.Item>

                                                </Col>
                                                <div className="flex justify-end w-full gap-5">
                                                    {(
                                                        <div className="flex gap-2">
                                                            <Button
                                                                type="default"
                                                                onClick={onClose}
                                                                className="h-10 text-black border-black hover:bg-black hover:text-white hover:transition hover:duration-500 hover:ease-in-out"
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                htmlType="submit"
                                                                loading={loading}
                                                                onClick={() => form.submit()}
                                                                className="h-10 bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out"
                                                            >
                                                                Update
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Row>
                                        </Form>
                                    </div>
                                </Drawer>
                                <Modal open={isVerifyModalVisible} footer={null} onCancel={handleCancel}>
                                    <div className='flex justify-center items-center'>
                                        <div className="p-6 w-full max-w-md">
                                            <div>
                                                <p className="text-center text-[32px] font-extrabold mb-6 text-black">Verify Your Authorization</p>
                                                <p className='text-center text-base mb-5'>Enter the 6-digit verification code sent to
                                                    {(isEmailType.current) ? (" " + form.getFieldValue("email") + " ") : (" " + form.getFieldValue("countryDialingCode") + " " + form.getFieldValue("phoneNo") + " ")} to confirm your authorization.</p>
                                                <Form form={otpForm} initialValues={{ otpCheck: "" }}>
                                                    <Flex gap="middle" justify='center' className='otp-box' vertical>
                                                        <Form.Item name="otpCheck">
                                                            <Input.OTP onKeyDown={(event) => handleOtpChange(event)} />
                                                        </Form.Item>
                                                    </Flex>
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit" className="h-14 w-full mt-6 text-xl bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer"
                                                            loading={modalLoading} onClick={onVerify}>
                                                            Agreed & proceed
                                                        </Button>
                                                    </Form.Item>
                                                    <div className="text-sm font-semibold text-center text-gray-500 mt-2 block">
                                                        {resendTimer > 0 ? (
                                                            <p className="font-semibold text-center text-gray-400 mt-2 block">
                                                                Resend OTP in {resendTimer}s
                                                            </p>
                                                        ) : (
                                                            <button onClick={onResend} type="button"
                                                                className="font-semibold text-center text-gray-500 border-none">
                                                                Resend OTP
                                                            </button>
                                                        )}
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </TabPane>
                        ))
                    }
                </Tabs>

                <Modal
                    title={<span className="custom-modal-title">{getModalTitle()}</span>}
                    open={isModalVisible}
                    onOk={handleSave}
                    onCancel={handleCancel}
                    closeIcon={<CloseOutlined className="text-gray-500 hover:text-black" />}
                    footer={[
                        <Button key="back" onClick={handleCancel} className="hover:!text-black hover:!border-black">
                            Cancel
                        </Button>,
                        <Button key="submit" onClick={handleSave} className="bg-black text-white hover:bg-transparent hover:!text-black hover:!border-black">
                            Confirm
                        </Button>,
                    ]}
                >
                    {getModelContent()}
                </Modal>
            </ConfigProvider>
        </div >
    );
}

export default Attendants;