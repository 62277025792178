import React, { useEffect, useState, useRef } from "react";
import { Input, Row, Col, message, Spin } from "antd";
import { useFetchChatList, useGetChatDetail } from '../services/chat.service';
import IResponse from "../types/response";
import { formatDateTime, formatDateWithOrdinalSuffix } from "../helpers/common";
import { IChatList, IChatDetail } from "../types/user";
import Images from "../assets/image";

const Chats: React.FC = () => {
    const { Search } = Input;
    const [chatList, setChatList] = useState<IChatList[]>([]);
    const [chatDetail, setChatDetail] = useState<IChatDetail[]>([]);
    const [selectedChat, setSelectedChat] = useState<{ travellerId: string, agentId: string, chatId: string, travellerName: string, cityName: string, tripStartDate: string, tripEndDate: Date } | null>(null);
    const searchValue = useRef<string>("");
    const [loading, setIsLoading] = useState(true);
    const [loadingChats, setLoadingChats] = useState(true);
    const chatDetailContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getChatList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (chatList.length > 0) {
            const firstChat = chatList[0];
            setSelectedChat(firstChat);
            getChatDetail(firstChat.travellerId, firstChat.agentId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatList]);

    useEffect(() => {
        if (chatDetailContainerRef.current) {
            chatDetailContainerRef.current.scrollTop = chatDetailContainerRef.current.scrollHeight;
        }
    }, [chatDetail]);

    const getChatList = () => {
        setLoadingChats(true);
        fetchChatListMutation.mutate({ searchFilter: searchValue.current });
    };

    const fetchChatListMutation = useFetchChatList({
        onSuccess: (response: IResponse) => {
            if (response.data.chatHistory != null && response.data.chatHistory !== undefined && Array.isArray(response.data.chatHistory)) {
                const sortedChatList = response.data.chatHistory.sort((a: IChatList, b: IChatList) => {
                    const dateA = new Date(a.lastUpdated);
                    const dateB = new Date(b.lastUpdated);
                    return dateB.getTime() - dateA.getTime();
                });
                setChatList(sortedChatList);
            } else {
                console.error("Unexpected response format:");
            }
            setLoadingChats(false);
        },
        onError: (error: any) => {
            message.error(error.message);
            setLoadingChats(false);
        }
    });

    const getChatDetail = async (travellerId: string, agentId: string) => {
        setIsLoading(true);
        getChatDetailMutation.mutate({ travellerId, agentId });
    };

    const getChatDetailMutation = useGetChatDetail({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && response != null && response !== undefined && Array.isArray(response.data)) {
                const sortedChatDetails = response.data.sort((a: IChatDetail, b: IChatDetail) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
                setChatDetail(sortedChatDetails);
            }
            else {
                message.error('Error fetching user chat details.');
            }
            setIsLoading(false);
        },
        onError: (error: any) => {
            message.error(error.message);
            setIsLoading(false);
        }
    });

    const handleChatSelection = (chat: IChatList) => {
        setSelectedChat(chat);
        getChatDetail(chat.travellerId, chat.agentId);
    }

    const onSearch = (value: string) => {
        searchValue.current = value;
        getChatList();
    };

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={8} lg={6}>
                    <div className="bg-[#f5f5f5] p-5 min-h-full h-[calc(100vh-175px)]">
                        <div className="mb-9">
                            <div className="mb-5">
                                <h2 className="text-3xl font-semibold text-black">Chats</h2>
                            </div>
                            <div className="flex justify-between gap-5">
                                <div className="w-80 max-w-80">

                                    <Search
                                        placeholder="Search for traveller, or attendant"
                                        size="large"
                                        enterButton
                                        className="search-field"
                                        onSearch={onSearch}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="overflow-y-scroll h-[calc(100vh-340px)] -mr-5 pr-2 mt-5">
                            {loadingChats ? (
                                <div className="flex justify-center items-center h-full">
                                    <Spin />
                                </div>
                            ) : (
                                chatList.length === 0 ? (
                                    <div className="ant-empty">
                                        <div className="ant-empty-image">
                                            <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                                <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                                    <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                                    <g fillRule="nonzero" stroke="#d9d9d9">
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="ant-empty-description">No data</div>
                                    </div>
                                ) : (chatList.map((item) => (
                                    <div
                                        key={item.travellerId}
                                        className={`mb-5 mr-2 bg-white p-2.5 rounded-md cursor-pointer shadow-custom 
                                        ${selectedChat?.chatId === item.chatId ? '!bg-[#cccccc2e] rounded-lg shadow-custom' : ''}`}
                                        onClick={() => handleChatSelection(item)}
                                    >
                                        <p className="text-base font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                                            {item.travellerName}'s trip to {item.cityName}
                                        </p>
                                        <div className="flex justify-between">
                                            <p className="text-gray-400 font-semibold">{item.agentName}</p>
                                            <p className="text-gray-400 text-[11px] text-end">
                                                {formatDateTime(item.lastUpdated, "MMM DD, YYYY, HH:mm AMPM")}
                                            </p>
                                        </div>
                                    </div>
                                ))))}
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={16} lg={18}>
                    <div className="bg-[#f5f5f5] p-5 min-h-full h-[calc(100vh-175px)]">
                        <div>
                            {loading || loading ? (
                                <p className="text-2xl font-semibold user-name pb-5">
                                </p>
                            ) : (
                                <p className="text-2xl font-semibold user-name pb-5">
                                    {selectedChat?.travellerName}'s trip to {selectedChat?.cityName} from {formatDateWithOrdinalSuffix(new Date(selectedChat?.tripStartDate ?? ""))} to {formatDateWithOrdinalSuffix(new Date(selectedChat?.tripEndDate ?? ""))}
                                </p>
                            )}
                        </div>
                        <div ref={chatDetailContainerRef} className="overflow-y-scroll h-[calc(100vh-300px)] pr-4 -mr-5 mt-8">
                            {loading ? (
                                <div className="flex justify-center items-center h-full">
                                    <Spin />
                                </div>
                                // ) : chatDetail.length === 0 ? (
                                //     <div className="ant-empty">
                                //         <div className="ant-empty-image">
                                //             <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                                //                 <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                //                     <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                                //                     <g fillRule="nonzero" stroke="#d9d9d9">
                                //                         <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                //                         <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
                                //                     </g>
                                //                 </g>
                                //             </svg>
                                //         </div>
                                //         <div className="ant-empty-description">No data</div>
                                //     </div>
                            ) : chatDetail.length > 0 ? (
                                <div className="w-full">
                                    {chatDetail.map((message, index) => (
                                        <div
                                            key={index}
                                            className={`flex items-start gap-4 w-full mb-4 ${message.senderId === selectedChat?.agentId ? 'text-left' : ''}`}
                                        >
                                            <div
                                                className={`p-2.5 bg-white rounded-md shadow-custom ${message.senderId === selectedChat?.agentId ? 'ml-auto' : ''}`}
                                            >
                                                <p className="text-lg font-semibold">{message.senderName}</p>
                                                <p>{message.text}</p>
                                                <p className="text-[11px] text-end text-gray-400">
                                                    {formatDateTime(message.timestamp, "MMM DD, YYYY, HH:mm AMPM")}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="h-full flex items-center justify-center">
                                    <div className="text-center">
                                        <div className="w-1/5 mx-auto">
                                            <img src={Images.nochat} alt="No Chat" />
                                        </div>
                                        <div className="mt-4">
                                            <p className="text-[22px]">No chat history between Attendants and Travellers, yet!</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Chats;