import { Row, Col, Select, Button, Form, Input, message, Modal, Flex, Checkbox } from 'antd';
import Images from '../assets/image/index';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import 'react-phone-input-2/lib/style.css'
import IResponse from '../types/response';
import { useCompleteUserProfile, useFetchUser, useSendVerificationCode, useUpdateVerifiedStatus, useVerifyUserVerificationCode, useGetAgentPayoutPreferences } from '../services/user.service';
import { IUser } from '../types/user';
import { getLabel, isNotNullUndefined, isNotNullUndefinedBlank, isValidEmail, isValidPhoneNumber, onAutoGeneratePassword } from '../helpers/common';
import { ICountry } from '../types/countries';
import { IState } from '../types/states';
// import { ICity } from '../types/cities';
import { useFetchCountry } from '../services/countries.service';
import { useFetchStates } from '../services/states.service';
import { useFetchCities } from '../services/cities.service';
import { useNavigate } from 'react-router-dom';
import { storage } from '../utils/storage';
import { useCompleteAgentProfile, useUpdateAgentStatus } from '../services/agent.service';
import { DeleteObjectCommand, DeleteObjectCommandInput, S3, ObjectCannedACL } from '@aws-sdk/client-s3';
import { Upload as S3Upload } from '@aws-sdk/lib-storage';
import { awsS3config } from '../helpers/config';
import { useTimer } from '../helpers/useTimer';
import { useUpdateUserStatus } from '../services/traveller.service';
import { checkPasswordStrength } from '../helpers/passwordstrength';
import { useFetchLanguages } from '../services/language.service';

const Completeprofile = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { Option } = Select;
    const [profileImage, setProfileImage] = useState(Images.userprofile);
    const [saveLoading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [otpForm] = Form.useForm();
    const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [country, setCountry] = useState<ICountry[]>([]);
    const [state, setState] = useState<IState[]>([]);
    const [cityList, setCityList] = useState<any[]>([]);
    const [cityOptions, setCityOptions] = useState<any[]>([]);
    const codeType = useRef('');
    const isEmailType = useRef(false);
    const [passwordScore, setPasswordScore] = useState<number>(0);
    const [password, setPassword] = useState('');

    const [isEmailVerified, setEmailVerified] = useState(false);
    const [isSmsVerified, setSmsVerified] = useState(false);
    const registeredBy = useRef<'emailId' | 'phoneNo' | string>('');
    //const [defaultCountry, setDefaultCountry] = useState<any>({});
    const { resendTimer, resetTimer } = useTimer();
    const [languageList, setLanguageList] = useState<any[]>([]);
    const [isProvideTransportation, setIsProvideTransportation] = useState(false);
    const [isNoTransportation, setIsNoTransportation] = useState(true);
    const [agentPayoutPreferences, setagentPayoutPreferences] = useState<any[]>([]);

    const isEmailValidate = useRef(false);

    const user = useRef<IUser>({
        userId: "",
        userRoleId: 0,
        userEmail: "",
        userStatusId: 0,
        userPhoneNumber: "",
        userCountryId: 0,
        userDialingCode: "",
        userPhoneNoCountryId: 0
    }).current;

    const [email, setEmail] = useState(user.userEmail);
    useEffect(() => {
        setEmail(user.userEmail);
    }, [user.userEmail]);

    const [phoneNo, setPhoneNo] = useState(user.userPhoneNumber);
    useEffect(() => {
        setPhoneNo(user.userPhoneNumber);
    }, [user.userPhoneNumber]);

    // useEffect(() => {
    //     form.setFieldsValue({ country: defaultCountry.countryId });
    // }, [defaultCountry, form]);

    useEffect(() => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        if (userRoleId === 1 || userRoleId === 0) {
            navigate("/");
        }
        else {
            fetchCountry();
            fetchAllLanguages();
            fetchAgentPayoutPreferences();
            setTimeout(() => { fetchUser(); }, 1000);
            storage.setPreviousUrlPath(window.location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = async () => {
        window.localStorage.clear();
        navigate('/');
    };

    const fetchUser = async () => {
        fetchUserMutation.mutate({});
    };

    const getRegisteredBy = () => {
        if (storage.getCodeType() === 'email' && storage.getEmailId() !== "") {
            return 'emailId';
        }
        else if (storage.getCodeType() === 'sms' && storage.getDialingCode() !== "" && storage.getPhoneNumber() !== "") {
            return 'phoneNo';
        } else if (isNotNullUndefinedBlank(storage.getLoginType())) {
            return storage.getLoginType();
        }
    }

    const handleUserStatus = (statusId: any, userId: string, userRoleId: number) => {
        const code = otpForm.getFieldValue("otpCheck") ?? "";
        if (statusId === 10 && code.trim().length === 6) {
            if (userRoleId === 2) {
                updateAgentStatus.mutate({ agentId: userId, statusCode: "OTP_VERIFIED", reason: "" });
            }
            else if (userRoleId === 3) {
                updateUserStatus.mutate({ travellerId: userId, statusCode: "OTP_VERIFIED", reason: "" });
            }
        } else {
            onUpdateVerifiedStatus();
        }
    }

    const updateAgentStatus = useUpdateAgentStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                onUpdateVerifiedStatus();
            }
            else {
                setModalLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setModalLoading(false);
            message.error(error.message);
        }
    });

    const updateUserStatus = useUpdateUserStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                onUpdateVerifiedStatus();
            }
            else {
                setModalLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setModalLoading(false);
            message.error(error.message);
        }
    });

    const onUpdateVerifiedStatus = async () => {
        updateVerifiedStatusMutation.mutate({ userId: storage.getUserId() ?? "", codeType: codeType.current });
    };

    const updateVerifiedStatusMutation = useUpdateVerifiedStatus({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                fetchUser();
            } else {
                message.error(response.statusMessage);
            }
        }, onError: (error) => {
            message.error(error.statusMessage);
        }
    });

    interface CountryRequestDto {
        countryId: number;
        countryName: string;
        imageUrl: string;
        dialingCode: string;
    }

    const fetchUserMutation = useFetchUser({
        onSuccess: (response: IResponse) => {
            if (response.statusCode === 200 && isNotNullUndefined(response.data)) {
                registeredBy.current = getRegisteredBy() ?? "";

                let defaultCountry: CountryRequestDto | undefined;

                if (isNotNullUndefinedBlank(response.data.phoneNoDialingCode)) {
                    defaultCountry = country.find((x) => x.dialingCode?.trim() === response.data.phoneNoDialingCode?.trim());
                } else {
                    defaultCountry = country.find(x => x.countryId === 1);
                }
                user.userId = response.data.userId;
                user.userEmail = response.data.email ? response.data.email : storage.getEmailId() ?? "";
                user.userRoleId = response.data.userRoleId;
                user.userStatusId = response.data.statusId ? response.data.statusId : storage.getUserStatusId() ?? "";
                user.userCountryId = (response.data.userRoleId === 2) ? (defaultCountry?.countryId ?? 1) : (response.data.countryId ?? 0) > 0 ? response.data.countryId : 1;
                user.userPhoneNumber = response.data.phoneNo ? response.data.phoneNo : storage.getPhoneNumber() ?? "";
                user.userDialingCode = response.data.phoneNoDialingCode ?? "";
                user.userPhoneNoCountryId = response.data.phoneNoCountryId;
                // user.userDialingCode = country.find(x => x.countryId === user.userCountryId)?.dialingCode ?? "";
                setEmailVerified(response.data.isEmailVerified);
                setSmsVerified(response.data.isPhoneNoVerified);
                storage.setUserStatusId(user.userStatusId);
                const isEmail = (user.userEmail !== "");
                codeType.current = (isEmail ? "email" : "sms");
                isEmailValidate.current = (isNotNullUndefinedBlank(registeredBy.current) && (registeredBy.current === 'emailId' || registeredBy.current === 'google'));
                form.setFieldsValue({
                    name: isNotNullUndefinedBlank(response.data.fullName) ? response.data.fullName : form.getFieldValue("name") ?? "",
                    email: user.userEmail,
                    phoneNumber: user.userPhoneNumber,
                    country: (response.data.userRoleId === 2) ? (defaultCountry?.countryId ?? 1) : (form.getFieldValue("country") ?? null)
                });
                if (isNotNullUndefinedBlank(response.data.imageUrl)) {
                    setProfileImage(response.data.imageUrl);
                }

                if (defaultCountry) {
                    const defaultOption = {
                        value: defaultCountry.countryId,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={defaultCountry.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
                                {defaultCountry.dialingCode} {defaultCountry.countryName}
                            </div>
                        )
                    };
                    const option = { value: defaultOption.value, label: defaultOption.label.props.children };
                    handleCountryCodeChange(option);
                    fetchState(defaultCountry.countryId.toString());
                }
            } else {
                message.error(response.statusMessage);
            }
        },
        onError: (error: any) => {
            message.error(error.message);
        }
    });

    const fetchCountry = async () => {
        let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
        const isServiceable = userRoleId === 2 ? true : "";

        fetchCountryMutation.mutate({
            searchFilter: "",
            isServiceable: isServiceable
        });
    };

    // const fetchCountryMutation = useFetchCountry({
    //     onSuccess: (response: IResponse) => {
    //         if (response != null && response !== undefined) {
    //             response.data = response;
    //             let userRoleId = parseInt(storage.getUserRoleId() ?? "0");
    //             if (userRoleId === 2) {
    //                 const defaultCountry = response.data.find((x: any) => x.countryId === 1);
    //                 setDefaultCountry(defaultCountry);
    //                 setCountry([defaultCountry]);
    //                 form.setFieldsValue({ country: defaultCountry.countryId });
    //                 fetchState(defaultCountry.countryId);
    //             } else {
    //                 setCountry(response.data);
    //                 setState([]);
    //                 setCityList([]);
    //                 setCityOptions([]);
    //             }

    //             if (defaultCountry) {
    //                 const defaultOption = {
    //                     value: defaultCountry.countryId,
    //                     label: (
    //                         <div style={{ display: 'flex', alignItems: 'center' }}>
    //                             <img src={defaultCountry.imageUrl} className='w-5' alt="Country Flag" style={{ marginRight: 8 }} />
    //                             {defaultCountry.dialingCode} {defaultCountry.countryName}
    //                         </div>
    //                     )
    //                 };
    //                 const option = { value: defaultOption.value, label: defaultOption.label.props.children };
    //                 handleCountryCodeChange(option);
    //             }
    //         } else {
    //             console.error("Unexpected response format:", response);
    //         }
    //     },
    //     onError: (error: any) => {
    //         alert(error.message);
    //     },
    // });

    const fetchCountryMutation = useFetchCountry({
        onSuccess: (response: any) => {
            if (response != null && response !== undefined) {
                setCountry(response);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error: any) => {
            alert(error.message);
        },
    });

    const fetchState = async (countryId: string = "") => {
        setCityList([]);
        setCityOptions([]);
        form.setFieldValue("state", "");
        form.setFieldValue("city", "");
        fetchStateMutation.mutate({
            countryId: countryId,
            searchFilter: ""
        });
    };

    const fetchStateMutation = useFetchStates({
        onSuccess: (response: any, request) => {
            if (isNotNullUndefined(response)) {
                setState(response.data);
                fetchCityOptions("", request.countryId);
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchCityOptions = (stateId: any, countryId: any) => {
        setCityOptions([]);
        fetchCity(stateId, countryId);
    }

    const fetchCity = async (stateId: string = "", countryId: string = "") => {
        setCityOptions([]);
        form.setFieldValue("city", "");
        form.setFieldValue("cities", null);
        if (isNotNullUndefinedBlank(stateId) && isNotNullUndefinedBlank(countryId)) {
            fetchCityMutation.mutate({
                stateId: stateId,
                countryId: countryId,
                searchFilter: "",
                isFavourite: "",
                isServiceable: ""
            });
        }
        else if (!isNotNullUndefinedBlank(stateId) && isNotNullUndefinedBlank(countryId)) {
            fetchCityMutation.mutate({
                stateId: "",
                countryId: countryId,
                searchFilter: "",
                isFavourite: "",
                isServiceable: true
            });
        }
    };

    const fetchCityMutation = useFetchCities({
        onSuccess: (response: any, request) => {
            if (isNotNullUndefined(response)) {
                if (isNotNullUndefinedBlank(request.stateId) && isNotNullUndefinedBlank(request.countryId)) {
                    setCityOptions(response.data);
                } else if (!isNotNullUndefinedBlank(request.stateId) && isNotNullUndefinedBlank(request.countryId)) {
                    setCityList(response.data);
                }
            } else {
                console.error("Unexpected response format:", response);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            if (isNotNullUndefinedBlank(profileImage) && profileImage !== Images.userprofile) {
                onDeleteProfileImageFile();
            }
            const userType = user.userRoleId === 2 ? "agent" : user.userRoleId === 3 ? "traveller" : "";
            const fileName = userType + "_profile" + new Date().getTime() + "." + file.name.split(".")[1];
            try {

                const bucket = new S3({
                    credentials: {
                        accessKeyId: awsS3config.accessKeyId,
                        secretAccessKey: awsS3config.secretAccessKey,
                    },
                    region: awsS3config.region,
                });

                const params = {
                    Bucket: awsS3config.bucketName,
                    Key: "temp/" + fileName,
                    Body: file,
                    ContentType: file.type,
                    ACL: ObjectCannedACL.public_read_write
                };

                const upload = new S3Upload({
                    client: bucket,
                    params: params,
                });
                upload.done().then((data) => {
                    const fileUrl = (data["Location"] !== '' && data["Location"] !== null && data["Location"] !== undefined) ? data["Location"] : "";
                    file.url = fileUrl;
                    setProfileImage(fileUrl);
                },
                    (err) => {
                        let errorJson = JSON.stringify(err);
                        let errorObject = JSON.parse(errorJson);
                        let msg = (errorObject["message"] !== '' && errorObject["message"] !== null && errorObject["message"] !== undefined) ? errorObject["message"] :
                            'Something went wrong. Please try again!';
                        message.error(msg);
                        return false;
                    });
            } catch (error) {
                message.error("Error uploading file.");
                return false;
            }

        }
    };

    const onDeleteProfileImageFile = async () => {
        if (isNotNullUndefinedBlank(profileImage)) {
            const fileName = profileImage?.split('/').pop();
            if (isNotNullUndefinedBlank(fileName)) {
                try {
                    const s3Client = new S3({
                        credentials: {
                            accessKeyId: awsS3config.accessKeyId,
                            secretAccessKey: awsS3config.secretAccessKey,
                        },
                        region: awsS3config.region,
                    });

                    const params: DeleteObjectCommandInput = {
                        Bucket: awsS3config.bucketName,
                        Key: profileImage?.includes("/temp/") ? "temp/" + fileName : profileImage?.includes("/agent/") ? "Agent/" + fileName : "Traveller/" + fileName
                    };

                    const command = new DeleteObjectCommand(params);
                    await s3Client.send(command);
                }
                catch (error) {
                    message.error("Error uploading file.");
                    return false;
                }
                setProfileImage("");
            }
        }
    };

    const handleEditClick = () => {
        const inputElement = document.getElementById('profileImageInput');
        if (inputElement) {
            inputElement.click();
        }
    };

    const onGenderChange = (value: string) => {
        switch (value) {
            case 'male':
                form.setFieldsValue({ note: 'Hi, man!' });
                break;
            case 'female':
                form.setFieldsValue({ note: 'Hi, lady!' });
                break;
            default:
        }
    };

    const handleCountryCodeChange = (option: any) => {
        if (Array.isArray(option.label)) {
            const slicedLabel = option.label.slice(0, 2);
            const modifiedOption = { ...option, label: slicedLabel };
            setSelectedCountry(modifiedOption);
        } else {
            setSelectedCountry(option);
        }
    };

    const onChangeEmailInput = (event: any) => {
        let currentValue = event.target.value;
        setEmail(currentValue);
        form.setFieldValue("email", currentValue);
    };

    const onChangePhoneInput = (event: any) => {
        let currentValue = event.target.value;
        if (!/^[0-9]+$/.test(currentValue)) {
            currentValue = currentValue.slice(0, -1);
        }
        if (currentValue.length > 12) {
            currentValue = currentValue.slice(0, 12);
        }
        setPhoneNo(currentValue);
        form.setFieldValue("phoneNumber", currentValue);
    };

    const onChangeHourlyRate = (e: any) => {
        let value = e.target.value.trim().replace(/[^0-9.]/g, '');

        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts.slice(0, 2).join('.');
        } else if (parts.length === 2) {
            value = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
        value = value.slice(0, 9);
        form.setFieldsValue({ hourlyRate: value });
    };

    const handleProvideTransportationChange = (e: any) => {
        setIsProvideTransportation(e.target.checked);
        if (e.target.checked) {
            setIsNoTransportation(false);
        }
    };

    const handleNoTransportationChange = (e: any) => {
        setIsNoTransportation(e.target.checked);
        if (e.target.checked) {
            setIsProvideTransportation(false);
        }
    };

    const onCompleteProfile = async (values: { name: string, email: string, phoneNumber: string, phoneNoCountryId: number, gender: string, bio: string, hourlyRate: string, languages: any, cities: any, transportation: boolean, address: string, country: number, state: number, city: number, payoutPreferenceId: number, accountInformation: string }) => {

        let phoneNoDialingCode = "";
        if (isNotNullUndefinedBlank(values.phoneNumber)) {
            phoneNoDialingCode = country.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "";
        }
        const transportationValue = isProvideTransportation ? 'true' : 'false';
        if (user.userStatusId === 9 || (user.userStatusId === 10 && registeredBy.current === 'google')) {
            if (user.userRoleId === 2) {
                completeAgentProfile.mutate({
                    agentId: user.userId,
                    fullName: values.name,
                    email: values.email,
                    phoneNoDialingCode: phoneNoDialingCode,
                    phoneNoCountryId: values.phoneNoCountryId,
                    phoneNo: values.phoneNumber,
                    imageUrl: profileImage,
                    gender: values.gender ?? "",
                    Bio: values.bio,
                    hourlyRate: parseInt(values.hourlyRate ?? 0),
                    countryId: values.country ?? 0,
                    stateId: values.state ?? 0,
                    cityId: values.city ?? 0,
                    address: values.address,
                    payoutPreferenceId: values.payoutPreferenceId,
                    accountInformation: values.accountInformation,
                    agentPreference: [{
                        preferenceName: "Cities",
                        preferenceValue: (isNotNullUndefined(values.cities) && isNotNullUndefined(values.cities.length > 0)) ? values.cities.join(",") : ""
                    },
                    {
                        preferenceName: "Languages",
                        preferenceValue: (isNotNullUndefined(values.languages) && isNotNullUndefined(values.languages.length > 0)) ? values.languages.join(",") : ""
                    },
                    {
                        preferenceName: "Transportation",
                        preferenceValue: transportationValue
                    }
                    ],
                    password: password
                });
            }
            else if (user.userRoleId === 3) {
                completeUserProfile.mutate({
                    fullName: values.name,
                    email: values.email,
                    phoneNoDialingCode: phoneNoDialingCode,
                    phoneNoCountryId: values.phoneNoCountryId,
                    phoneNo: values.phoneNumber,
                    imageUrl: profileImage,
                    gender: values.gender ?? "",
                    address: values.address,
                    payoutPreferenceId: values.payoutPreferenceId,
                    accountInformation: values.accountInformation,
                    countryId: values.country ?? "",
                    stateId: values.state ?? "",
                    cityId: values.city ?? "",
                    password: password
                });
            }

        } else {
            message.error(`Please verify the ${(user.userRoleId === 2) ? 'attendant' : 'traveller'}'s ${codeType.current === "email" ? 'email address' : 'phone number'}.`);
            setLoading(false);
        }
        setLoading(true);
    };

    const completeUserProfile = useCompleteUserProfile({
        onSuccess: (response: IResponse) => {
            setLoading(false);
            if (response.statusCode === 200) {
                message.success(response.statusMessage);
                navigate("/complete");
            } else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            setLoading(false);
            message.error(error.message);
        }
    });

    const completeAgentProfile = useCompleteAgentProfile({
        onSuccess: (response: IResponse) => {
            setLoading(false);
            if (response.statusCode === 200) {
                message.success(response.statusMessage);
                navigate("/agentdocument?id=" + user.userId);
            } else {
                message.warning(response.statusMessage);
            }
        },
        onError: (error: any) => {
            setLoading(false);
            message.error(error.message);
        }
    });

    const sendVerificationCodeMutation = useSendVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                message.success("OTP sent successfully.");
                setIsVerifyModalVisible(true);
            }
            else {
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            message.error(error.message);
        }
    });

    const openVerifyModal = (verificationType: string) => {
        isEmailType.current = (verificationType === "email");
        const { current: isEmail } = isEmailType;

        if ((email === "" && isEmail) || (email !== "" && isEmail && !isValidEmail(email))) {
            message.error("Please enter a valid email address");
            return;
        }
        if ((phoneNo === "" && !isEmail) || (phoneNo !== "" && !isEmail && !isValidPhoneNumber(phoneNo))) {
            message.error("Please enter a valid phone number");
            return;
        }
        otpForm.setFieldValue("otpCheck", "");

        if (isEmailType.current) {
            user.userEmail = email;
        } else {
            user.userPhoneNumber = phoneNo;
        }
        form.setFieldValue("email", user.userEmail);
        form.setFieldValue("phoneNumber", user.userPhoneNumber);
        let countryId = user.userCountryId > 0 ? user.userCountryId : selectedCountry.value;
        user.userDialingCode = country.find(x => x.countryId === countryId)?.dialingCode ?? "";
        sendVerificationCodeMutation.mutate({
            userId: storage.getUserId() ?? "",
            userEmail: (verificationType === "email") ? user.userEmail : "",
            phoneNumber: (verificationType === "sms") ? user.userPhoneNumber : "",
            phoneNoDialingCode: country.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? ""
        });
        codeType.current = verificationType;
    };

    const onVerify = () => {
        const code = otpForm.getFieldValue("otpCheck");
        if (isNotNullUndefinedBlank(code) && code.length === 6) {
            setModalLoading(true);
            verifiyVerificationCodeMutation.mutate({ userEmail: user.userEmail, phoneNumber: user.userPhoneNumber, verificationCode: code, codeType: codeType.current });
        } else {
            message.warning("OTP must be 6 digits.");
        }
    };

    const handleCancel = () => {
        otpForm.setFieldValue("otpCheck", "");
        setIsVerifyModalVisible(false);
    };

    const onResend = () => {
        sendVerificationCodeMutation.mutate({ userEmail: user.userEmail, phoneNumber: user.userPhoneNumber, phoneNoDialingCode: country.find(x => x.countryId === selectedCountry.value)?.dialingCode ?? "" });
        resetTimer();
    };

    const verifiyVerificationCodeMutation = useVerifyUserVerificationCode({
        onSuccess: (response: any) => {
            if (response.statusCode === 200) {
                handleUserStatus(user.userStatusId, user.userId, user.userRoleId);
                message.success(response.statusMessage);
                storage.setToken(response.data.token);
                storage.setRefreshToken(response.data.refreshToken);
                storage.setUserRoleId(response.data.userRoleId);
                if (!isEmailVerified) {
                    setEmailVerified((email !== "" && codeType.current === "email" && response.statusCode === 200));
                }
                if (!isSmsVerified) {
                    setSmsVerified((phoneNo !== "" && codeType.current === "sms" && response.statusCode === 200));
                }
                storage.setEmailId(email);
                storage.setPhoneNumber(phoneNo);
                setModalLoading(false);
                setIsVerifyModalVisible(false);
            }
            else {
                setModalLoading(false);
                message.error(response.statusMessage);
            }
        },
        onError: (error) => {
            setModalLoading(false);
            message.error(error.message);
        }
    });

    const handleOtpChange = (event: any) => {
        const isNumberKey = !isNaN(event.key);
        const children = event.currentTarget.children;
        const targetIndex = Array.prototype.indexOf.call(children, event.target);
        let newValue = '';
        Array.prototype.forEach.call(children, (child, index) => {
            if (index === targetIndex) {
                newValue += event.key;
            } else {
                newValue += child.defaultValue;
            }
        });
        const isValidInput = !isNaN(Number(newValue));
        if (!isNumberKey || !isValidInput) {
            if (event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
                if (/[a-zA-Z\W]/.test(event.key)) {
                    message.error('OTP must only contain numbers.');
                    event.preventDefault();
                }
            }
            return false;
        }
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = event.clipboardData.getData('text');
        if (/[a-zA-Z\W]/.test(pastedData)) {
            message.error('OTP must only contain numbers.');
            event.preventDefault();
            console.clear();
        }
    };

    const getAutoGeneratePassword = () => {
        const password = onAutoGeneratePassword();
        form.setFieldValue("password", password)
        setPassword(password);
        setPasswordScore(checkPasswordStrength(password));
    };

    const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "password") {
            const trimmedValue = value.replace(/\s+/g, '');
            setPasswordScore(checkPasswordStrength(value));
            setPassword(trimmedValue);
            form.setFieldValue(name, trimmedValue);
        }
    };

    const fetchAllLanguages = async () => {
        fetchAllLanguageMutation.mutate({});
    };

    const fetchAllLanguageMutation = useFetchLanguages({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response.data)) {
                setLanguageList(response.data);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    const fetchAgentPayoutPreferences = async () => {
        fetchAgentPayoutPreferencesMutation.mutate({});
    };

    const fetchAgentPayoutPreferencesMutation = useGetAgentPayoutPreferences({
        onSuccess: (response: any) => {
            if (isNotNullUndefined(response.data)) {
                setagentPayoutPreferences(response.data);
            }
        },
        onError: (error) => {
            alert(error.message);
        },
    });

    return (
        <div className="app-container">
            <Row className="min-h-screen">
                <Col span={12} className="hidden lg:block bg-black h-screen">
                    <div className='h-screen bg-login-background bg-no-repeat bg-cover flex justify-center items-center'>
                        <div>
                            <div className='flex justify-center w-full'>
                                <div className='w-72'>
                                    <img src={Images.triplogo} className='w-full' alt="triplogo" />
                                </div>
                            </div>
                            <div className='mt-20 flex justify-center'>
                                <img src={Images.travellogo} className='w-[335px] 2xl:w-[443px]' alt="travel-logo" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} lg={12}>
                    <div className='block  lg:w-0 lg:h-0 lg:hidden'>
                        <div className='flex justify-center py-8'>
                            <img src={Images.mobilelogo} className='w-[140px] h-[60px] lg:w-[219px] lg:h-[96px]' alt="triplogo" />
                        </div>
                    </div>
                    <div className='flex justify-center overflow-y-scroll h-full'>
                        <div className="p-6 w-full max-w-md h-screen">
                            <div>
                                <p className="text-center text-2xl lg:text-[32px] font-extrabold mb-6 text-black">Complete your profile</p>
                                <div className="flex justify-center">
                                    <div className="w-[90px] h-[90px]">
                                        <img
                                            src={profileImage}
                                            className="rounded-full w-full h-full shadow-lg border-4 border-black object-cover"
                                            alt="user"
                                        />
                                    </div>
                                    <div
                                        className="h-7 w-7 text-lg rounded-full bg-black relative flex items-center border-4 border-black justify-center top-[63px] right-[33px] transform origin-center cursor-pointer"
                                        onClick={handleEditClick}
                                    >
                                        <div className="w-3 h-3">
                                            <img src={Images.imageedit} className="w-full h-full" alt="edit-img" />
                                        </div>
                                    </div>
                                    <input
                                        type="file"
                                        id="profileImageInput"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                <Form
                                    layout="vertical"
                                    form={form}
                                    className="space-y-4"
                                    initialValues={{ name: '', address: '', bio: '', hourlyRate: '' }}
                                    onFinish={onCompleteProfile}
                                >
                                    <Form.Item
                                        className='font-semibold'
                                        label="Full Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true, message: 'Please input your name',
                                            },
                                        ]}
                                        validateTrigger="onBlur"
                                    >
                                        <Input className="font-medium text-neutral-600 h-12" placeholder="Enter your name" />
                                    </Form.Item>
                                    <Form.Item
                                        className='font-semibold'
                                        label={getLabel("Email", isEmailValidate.current)}
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid e-mail!',
                                            },
                                            {
                                                required: isEmailValidate.current,
                                                message: 'Please input your e-mail!',
                                            },
                                        ]}
                                        validateTrigger="onBlur"
                                    >
                                        <Input className="font-medium text-neutral-600 h-12 complete-profile-email" placeholder="Enter your email" disabled={isNotNullUndefinedBlank(user.userEmail) && isEmailVerified} onInput={onChangeEmailInput} value={email} />
                                        {
                                            (isEmailVerified) ?
                                                <div>
                                                    <img src={Images.righticon} alt="Right-icon" className='absolute top-3.5 right-3 w-[18px]' />
                                                </div>
                                                : ((user.userStatusId === 10 && codeType.current === "email") || !isEmailVerified) ?
                                                    <div className='cursor-pointer'>
                                                        {email && (<p onClick={() => openVerifyModal('email')} className='absolute top-3.5 right-3 text-blue-500 font-medium cursor-pointer'>Verify</p>)}
                                                    </div> : <></>
                                        }

                                        <Modal open={isVerifyModalVisible} footer={null} onCancel={handleCancel}>
                                            <div className='flex justify-center items-center'>
                                                <div className="p-6 w-full max-w-md">
                                                    <div>
                                                        <p className="text-center text-[32px] font-extrabold mb-6 text-black">Verify Your Authorization</p>
                                                        <p className='text-center text-base mb-5'>Enter the 6-digit verification code sent to
                                                            {(isEmailType.current) ? (" " + user.userEmail + " ") : (" " + user.userDialingCode + " " + user.userPhoneNumber + " ")} to confirm your authorization.</p>
                                                        <Form form={otpForm} initialValues={{ otpCheck: "" }}>
                                                            <Flex gap="middle" justify='center' className='otp-box' vertical>
                                                                <Form.Item name="otpCheck">
                                                                    <Input.OTP
                                                                        id='otpNumber'
                                                                        onKeyDown={(event) => handleOtpChange(event)}
                                                                        onPaste={(event) => handlePaste(event as React.ClipboardEvent<HTMLInputElement>)}
                                                                    />
                                                                </Form.Item>
                                                            </Flex>
                                                            <Form.Item>
                                                                <Button type="primary" htmlType="submit" className="h-14 w-full mt-6 text-xl bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer"
                                                                    loading={modalLoading} onClick={onVerify}>
                                                                    Agreed & proceed
                                                                </Button>
                                                            </Form.Item>
                                                            <div className="text-sm font-semibold text-center text-gray-500 mt-2 block">
                                                                {resendTimer > 0 ? (
                                                                    <p className="font-semibold text-center text-gray-400 mt-2 block">
                                                                        Resend OTP in {resendTimer}s
                                                                    </p>
                                                                ) : (
                                                                    <button onClick={onResend} type="button"
                                                                        className="font-semibold text-center text-gray-500 border-none">
                                                                        Resend OTP
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal>
                                    </Form.Item>
                                    {
                                        (isNotNullUndefinedBlank(registeredBy.current) && registeredBy.current !== 'emailId' && isEmailVerified) ?
                                            <div>
                                                <Form.Item className="font-semibold auto-password mb-2.5" name="password" label={
                                                    <div className="flex justify-between w-full">
                                                        <span>Password</span>
                                                        <button
                                                            className="mb-2 underline cursor-pointer select-none outline-none text-black text-xs relative -bottom-3 hover:text-black"
                                                            onClick={getAutoGeneratePassword}>
                                                            Auto Generate Password
                                                        </button>
                                                    </div>}
                                                    rules={[{ required: true, message: "Please enter password." }]}
                                                    validateTrigger="onBlur">
                                                    <Input.Password className="font-medium text-neutral-600 h-12"
                                                        id='ddlPassword'
                                                        placeholder="Enter your password"
                                                        value={password}
                                                        name="password"
                                                        onChange={inputChange}
                                                    />
                                                </Form.Item>

                                                <div className="flex -mx-1">
                                                    {Array.from({ length: 5 }).map((_, i) => (
                                                        <div key={i} className="w-1/5 px-1">
                                                            <div
                                                                className={`h-1 rounded-xl transition-colors ${i < passwordScore
                                                                    ? passwordScore <= 2
                                                                        ? "bg-red-600"
                                                                        : passwordScore <= 2
                                                                            ? "bg-orange-400"
                                                                            : passwordScore <= 4
                                                                                ? "bg-yellow-400"
                                                                                : "bg-green-500"
                                                                    : "bg-slate-300"
                                                                    }`}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='text-end mt-1'>
                                                    <span className={`${passwordScore !== 0 ? (passwordScore > 0 && passwordScore <= 2) ? 'text-red-600' :
                                                        passwordScore <= 3 ? 'text-orange-400' :
                                                            passwordScore <= 4 ? 'text-yellow-400'
                                                                : 'text-green-500' : 'text-slate-300'} 
                                                    text-10 font-semibold`} >
                                                        {passwordScore !== 0 ? (passwordScore > 0 && passwordScore <= 2 ? 'Low' : passwordScore <= 3 ? 'Weak' : passwordScore <= 4 ? 'Medium' : 'Strong') : ''}
                                                    </span>
                                                </div>
                                            </div> :
                                            <></>
                                    }
                                    {/* <Form.Item
                                        className="font-semibold"
                                        label={getLabel("Phone number", !isEmailValidate.current)}
                                        name="phoneNumber"
                                        rules={[
                                            {
                                                type: 'regexp',
                                                pattern: /^\d{10}$/,
                                                message: 'The input is not valid Phone number!',
                                            },
                                            {
                                                required: !isEmailValidate.current,
                                                message: 'Please input your phone number!',
                                            },
                                        ]}
                                        validateTrigger="onBlur"
                                    >
                                        <Input
                                            addonBefore={
                                                <Select
                                                    labelInValue
                                                    value={{ value: selectedCountry?.value, label: selectedCountry?.label }}
                                                    onChange={handleCountryCodeChange}
                                                    className="h-12 w-[90px] country-code"
                                                    popupClassName="dropdown-max-content"
                                                // disabled={user.userRoleId === 2}
                                                >
                                                    {country ? country.map(x => (
                                                        <Option key={x.countryId} value={x.countryId}>
                                                            <img src={x.imageUrl} className='w-5' alt="Country Flag" />
                                                            {x.dialingCode} {x.countryName}
                                                        </Option>
                                                    )) : ""}
                                                </Select>
                                            }
                                            onChange={onChangePhoneInput} className="font-medium text-neutral-600 h-12 phone-input" placeholder="Enter your phone number"
                                            disabled={isNotNullUndefinedBlank(user.userPhoneNumber) && isSmsVerified} value={phoneNo}
                                            maxLength={10} />
                                        {
                                            (!isEmailValidate.current && isSmsVerified) && (
                                                <div>
                                                    <img src={Images.righticon} alt="Right-icon" className='absolute top-3.5 right-3 w-[18px]' />
                                                </div>
                                            )
                                        }
                                        {
                                            (!isEmailValidate.current && ((user.userStatusId === 10 && codeType.current === "sms") || !isSmsVerified)) && (
                                                <div className='cursor-pointer'>
                                                    {phoneNo && (<p onClick={() => openVerifyModal('sms')} className='absolute z-10 top-3.5 right-3 text-blue-500 font-medium cursor-pointer'>Verify</p>)}
                                                </div>
                                            )
                                        }
                                    </Form.Item> */}
                                    <Form.Item
                                        className="font-semibold"
                                        name="phoneNumber"
                                        label="Phone number"
                                        //rules={[{ required: true, message: "Please input your Phone number." }]}

                                        rules={[
                                            { required: true, message: "Please input your Phone number." },
                                            {
                                                validator: (_, value) => {
                                                    if (value && (value.length < 7 || value.length > 12)) {
                                                        return Promise.reject(new Error("Phone number must be between 7 and 12 digits."));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            addonBefore={
                                                <Select
                                                    labelInValue
                                                    value={{ value: selectedCountry?.value, label: selectedCountry?.label }}
                                                    className="h-12 w-[90px] country-code"
                                                    optionFilterProp="label"
                                                    onChange={handleCountryCodeChange}
                                                    options={country ? country.map(x => ({
                                                        label: (
                                                            <div className="flex items-center">
                                                                <img src={x.imageUrl} className='w-5 mr-2' alt="Country Flag" />
                                                                {x.dialingCode} {x.countryName}
                                                            </div>
                                                        ),
                                                        value: x.countryId
                                                    })) : []}
                                                    popupClassName="dropdown-max-content"
                                                // disabled={true}
                                                />
                                            }
                                            onChange={onChangePhoneInput} className="font-normal text-neutral-600 h-12 phone-input" placeholder="Enter your phone number"
                                            maxLength={12} />

                                    </Form.Item>
                                    <Form.Item name="gender" className='font-semibold' label="Gender" rules={[{ required: true, message: "Please select gender." }]}>
                                        <Select className='font-medium text-neutral-600 h-12'
                                            placeholder="Select gender"
                                            onChange={onGenderChange}
                                            allowClear
                                        >
                                            <Option value="male">Male</Option>
                                            <Option value="female">Female</Option>
                                        </Select>
                                    </Form.Item>
                                    {user.userRoleId === 2 && (
                                        <Form.Item
                                            name="languages"
                                            className='font-semibold'
                                            label="Language"
                                            rules={[{ required: true, message: "Please select any language." }]}
                                            validateTrigger="onBlur"
                                        >
                                            <Select
                                                showSearch
                                                className="font-medium text-neutral-600 h-12"
                                                placeholder="Please select Language"
                                                optionFilterProp="label"
                                                options={languageList ? languageList.map(x => ({ label: x.languageName, value: x.languageId })) : []}
                                                allowClear
                                                mode="multiple"
                                            />
                                        </Form.Item>
                                    )}

                                    {user.userRoleId === 2 && (
                                        <Form.Item
                                            className='font-semibold'
                                            label="Attendant Bio"
                                            name="bio"
                                            rules={[{ required: true, message: "Please enter your agentBio" }]}
                                            validateTrigger="onBlur"
                                        >
                                            <Input className="font-medium text-neutral-600 h-12" placeholder="Enter your bio" />
                                        </Form.Item>
                                    )}
                                    {user.userRoleId === 2 && (
                                        <Form.Item
                                            className='font-semibold'
                                            label="Hourly Rate"
                                            name="hourlyRate"
                                            rules={[{ required: true, message: "Please enter your hourlyRate" }]}
                                            validateTrigger="onBlur"
                                        >
                                            <Input className="font-medium text-neutral-600 h-12" placeholder="Enter your hourly rate" onChange={onChangeHourlyRate} />
                                        </Form.Item>
                                    )}
                                    <Form.Item
                                        name="country"
                                        className='font-semibold'
                                        label="Country"
                                        rules={[{ required: true, message: "Please select your country" }]}
                                        validateTrigger="onBlur"
                                    >
                                        <Select
                                            showSearch
                                            className="font-medium text-neutral-600 h-12"
                                            placeholder="Please select a Country"
                                            optionFilterProp="label"
                                            options={country ? country.map(x => ({ label: x.countryName, value: x.countryId })) : []}
                                            onChange={(value) => {
                                                fetchState(value);
                                            }}
                                            allowClear
                                        // disabled={user.userRoleId === 2}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="state"
                                        className='font-semibold'
                                        label="State/Province"
                                        rules={[{ required: true, message: "Please select your state or province" }]}
                                        validateTrigger="onBlur"
                                    >
                                        <Select
                                            showSearch
                                            className="font-medium text-neutral-600 h-12"
                                            placeholder="Please select State/Province"
                                            optionFilterProp="label"
                                            options={state ? state.map(x => ({ label: x.stateName, value: x.stateId })) : []}
                                            onChange={() => fetchCityOptions(form.getFieldValue("state"), form.getFieldValue("country"))}
                                            allowClear
                                            onClear={() => { setCityList([]); setCityOptions([]) }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="city"
                                        className='font-semibold'
                                        label="City"
                                        rules={[{ required: true, message: "Please enter your city" }]}
                                        validateTrigger="onBlur"
                                    >
                                        <Select
                                            showSearch
                                            className="font-medium text-neutral-600 h-12"
                                            placeholder="Please select City"
                                            optionFilterProp="label"
                                            options={cityOptions ? cityOptions.map(x => ({ label: x.cityName, value: x.cityId })) : []}
                                            allowClear
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        className='font-semibold'
                                        label="Address"
                                        name="address"
                                        rules={[{ required: true, message: "Please enter your address" }]}
                                        validateTrigger="onBlur"
                                    >
                                        <Input className="font-medium text-neutral-600 h-12" placeholder="Enter your address" />
                                    </Form.Item>
                                    {user.userRoleId === 2 && (
                                        <Form.Item
                                            name="cities"
                                            className='font-semibold'
                                            label="Select Service Cities"
                                            rules={[{ required: true, message: "Please select any prefered cities" }]}
                                            validateTrigger="onBlur"
                                        >
                                            <Select
                                                showSearch
                                                className="font-medium text-neutral-600 min-h-12 multiple-country"
                                                placeholder="Please select Cities"
                                                optionFilterProp="label"
                                                options={cityList ? cityList.map(x => ({ label: x.cityName, value: x.cityId })) : []}
                                                allowClear
                                                mode="multiple"
                                            />
                                        </Form.Item>
                                    )}

                                    {user.userRoleId === 2 && (
                                        <Form.Item name="transportation" label="Check this box if you will provide transportation services for travellers during trips. This will help travellers understand your availability for transportation and plan their trips accordingly.">
                                            <div className="check-box">
                                                <Checkbox
                                                    checked={isProvideTransportation}
                                                    onChange={handleProvideTransportationChange}
                                                >
                                                    Provide Transportation Services
                                                </Checkbox>
                                            </div>
                                            <div className="check-box">
                                                <Checkbox
                                                    checked={isNoTransportation}
                                                    onChange={handleNoTransportationChange}
                                                >
                                                    No Transportation Services
                                                </Checkbox>
                                            </div>
                                        </Form.Item>
                                    )}

                                    {user.userRoleId === 2 && (
                                        <Form.Item
                                            name="payoutPreferenceId"
                                            className='font-semibold'
                                            label="Payout Details"
                                            validateTrigger="onBlur"
                                        >
                                            <Select
                                                showSearch
                                                className="font-medium text-neutral-600 min-h-12 multiple-country"
                                                placeholder="Please select Payout Option"
                                                optionFilterProp="label"
                                                options={agentPayoutPreferences ? agentPayoutPreferences.map(x => ({ label: x.payoutPreferenceName, value: x.payoutPreferenceId })) : []}
                                                allowClear
                                            />
                                        </Form.Item>
                                    )}

                                    {user.userRoleId === 2 && (
                                        <Form.Item
                                            className='font-semibold'
                                            label="Account Information"
                                            name="accountInformation"
                                            validateTrigger="onBlur"
                                        >
                                            <Input.TextArea
                                                className="!h-[150px] font-medium text-neutral-600 h-12"
                                                placeholder="Please provide account details."
                                                style={{ resize: 'none', paddingTop: '10px' }}
                                                maxLength={500}
                                            />
                                        </Form.Item>
                                    )}

                                    {user.userRoleId === 2 && (
                                        <Form.Item name="transportation" label="Follow this link to see how you can setup any of the above payments if you don’t already have one.">
                                            <div className="text-center">
                                                <a
                                                    href="https://example.com/payment-setup"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-red-500 font-semibold underline"
                                                >
                                                    Payment Setup
                                                </a>
                                            </div>
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={saveLoading} className="h-14 pb- w-full mt-6 text-xl bg-black text-white hover:!border-black hover:!bg-transparent hover:!text-black hover:transition hover:duration-500 hover:ease-in-out cursor-pointer">Save</Button>
                                    </Form.Item>
                                    <Form.Item className='text-center flex items-center justify-center pb-9'>
                                        <Button onClick={logout} type="link" className="outline-none flex justify-center items-center cursor-pointer text-black text-base font-semibold underline">Log out</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Completeprofile;